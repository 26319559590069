import { marked } from 'marked'
import { Paper, List, Card, Tabs, Group, Text, ScrollArea, CopyButton, ActionIcon, Space, Code, Tooltip, rem } from '@mantine/core'
import { BroadnError, MarkdownText } from './Types'
import { Link } from 'react-router-dom'
import { IconCheck, IconMarkdown, IconCopy, IconBrandFirefox, IconMessageCircle } from '@tabler/icons-react'
import { encode, encodeChat, isWithinTokenLimit, encodeGenerator } from 'gpt-tokenizer'
import { JSONTree } from 'react-json-tree'

export function createLink(url: string, label?: string) {
  return (
    <Link target="_blank" to={url.trim()} className="linkStyle">
      <Text fz="xs">{label ? label : url}</Text>
    </Link>
  )
}

export function stringToTextOrLink(c: string) {
  if (!c) return null
  if (c.startsWith('http')) return createLink(c)
  return c
}

const iconStyle = { width: rem(20), height: rem(20) }

function numberWithKs(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function createJSObjectDisplay(val: string) {
  return (
    <Paper p="xs" shadow="xs" withBorder fz="xs">
      <Text component="pre" fz="xs" span={true}>
        <code style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', overflowWrap: 'break-word' }}>{val}</code>
      </Text>
    </Paper>
  )
}
export function createMarkdownDisplay(outObj: any) {
  if (!outObj) return null

  let output: string = ''
  switch (outObj._tag) {
    case 'MarkdownText':
    case 'PlainText':
    case 'HtmlContent':
      output = outObj.text
      break

    case 'UserBrief':
      output = outObj.executionBrief.brief
      break

    case 'RunLLMPrompt':
      output = outObj.prompt
      break
  }

  const tokenLength = numberWithKs(encode(output).length)

  return (
    <Tabs defaultValue="rawCode" variant="outline" fz="xs">
      <Tabs.List>
        <Tabs.Tab value="rawCode" leftSection={<IconMarkdown style={iconStyle} stroke={1} />}>
          Raw Markdown
        </Tabs.Tab>
        <Tabs.Tab value="renderedHtml" leftSection={<IconBrandFirefox style={iconStyle} stroke={1} />}>
          Rendered HTML
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="rawCode" className="scrollAreaInDefaultTab">
        <ScrollArea type="auto" scrollbarSize={4} h={'50vh'}>
          <Group>
            <CopyButton value={output} timeout={1200}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck style={{ width: rem(16) }} /> : <IconCopy style={{ width: rem(16) }} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
            <Space />
            <Text size={'xs'}>Tokens: {tokenLength}</Text>
          </Group>
          <Code block ta={'left'} fz="xs" className="codeBlock">
            {output}
          </Code>
        </ScrollArea>
      </Tabs.Panel>

      <Tabs.Panel value="renderedHtml" className="scrollAreaInDefaultTab">
        <ScrollArea type="auto" scrollbarSize={4} h={'50vh'}>
          <Text size="xs" dangerouslySetInnerHTML={{ __html: marked.parse(output, { async: false, gfm: true, pedantic: false }) as string }}></Text>
        </ScrollArea>
      </Tabs.Panel>
    </Tabs>
  )
}

export function createRefSection(ref: string | undefined | null, wrapped: boolean = true) {
  if (!ref) return null

  const content = (
    <>
      <Text span inherit c="var(--mantine-color-anchor)" fz="xs">
        Refs:
      </Text>
      <List fz="xs">
        {ref
          .split(',')
          .map((e) => e.trim())
          .map((e, i) => (
            <List.Item key={i} fz="xs">
              {stringToTextOrLink(e)}
            </List.Item>
          ))}
      </List>
    </>
  )

  if (wrapped) {
    return (
      <Card.Section withBorder inheritPadding py="xs">
        {content}
      </Card.Section>
    )
  }
  return content
}

function mapErrorToList(err: BroadnError, index: number) {
  return (
    <List.Item key={index} fz="xs">
      <Text fw={500} fz="xs">
        {err.name}
      </Text>
      <List>
        <List.Item>{err.message.replaceAll(',', ', ')}</List.Item>
        {err.ref ? <List.Item>Ref: {stringToTextOrLink(err.ref)}</List.Item> : null}
        {err.location ? <List.Item>Loc: {err.ref}</List.Item> : null}
        {err.errorList && err.errorList.length > 0 ? <List.Item>{err.errorList.map(mapErrorToList)}</List.Item> : null}
      </List>
    </List.Item>
  )
}

export function displayInputType(input: any) {
  if (!input) return null
  if (!input._tag) return objectPropsAsList(input)

  switch (input._tag) {
    case 'InputUrl':
      return createLink(input.url)
    case 'YouTubeUrl':
      const url = `https://youtu.be/${input.videoID}`
      return createLink(url)
    case 'VideoSubtitle':
      return createLink(input.ref)

    case 'GoogleSpreadsheetUrl':
      return createLink(`https://docs.google.com/spreadsheets/u/1/d/${input.spreadsheetID}/edit?usp=sharing`)

    default:
      return input._tag || `${input._tag} not yet defined in displayInputType`
  }
}

export const flattenObject = (p: object, entries: [string, any][]) => {
  if (Array.isArray(p)) {
    p.forEach((el) => flattenObject(el, entries))
  } else if (typeof p === 'object') {
    Object.keys(p).forEach((k) => {
      if (p[k]) {
        if (typeof p[k] === 'object') {
          flattenObject(p[k], entries)
        } else {
          if (!['_tag', 'text', 'prompt', 'broadnID', 'name', 'content'].includes(k)) entries.push([k, p[k]])
        }
      }
    })
  }
}

export function objectPropsAsList(payload: object) {
  if (!payload || typeof payload !== 'object') return null
  const entries: [string, any][] = []
  flattenObject(payload, entries)

  const uniq = {}
  entries
    .filter(([k, v]) => !['_tag', 'text'].includes(k))
    .forEach(([k, v]) => {
      uniq[k] = v
    })

  return (
    <List>
      {Object.entries(uniq)
        .filter((el) => el)
        .map(([k, v], i) => {
          return (
            <List.Item key={i}>
              <Text size={'sm'}>
                {k} :{' '}
                <Text span c={'indigo.8'}>
                  {v as any}
                </Text>
              </Text>
            </List.Item>
          )
        })}
    </List>
  )
}

export function createErrorSection(errors: BroadnError[], wrapped: boolean = true) {
  if (!errors || errors.length <= 0) return null

  const content = (
    <>
      <Text span fw={600} inherit c="red.6">
        Errors:
      </Text>
      <Text size="sm" c="dimmed" ta="left" style={{ textWrap: 'wrap' }}>
        <List className="breakLongLines">{errors.map((err, i) => mapErrorToList(err, i))}</List>
      </Text>
    </>
  )

  if (wrapped) {
    return (
      <Card.Section withBorder inheritPadding py="xs">
        {content}
      </Card.Section>
    )
  }
  return content
}

export function createDisplayForObject(dataObject: any) {
  if (dataObject)
    switch (dataObject._tag) {
      case 'TaskContext':
        return (
          <ScrollArea type="auto" scrollbarSize={4} h={'80vh'}>
            <CopyButton value={JSON.stringify(dataObject)} timeout={1200}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck style={{ width: rem(16) }} /> : <IconCopy style={{ width: rem(16) }} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
            <JSONTree
              data={dataObject}
              theme={{
                scheme: 'bright',
                base00: '#000000',
                base01: '#303030',
                base02: '#505050',
                base03: '#b0b0b0',
                base04: '#d0d0d0',
                base05: '#e0e0e0',
                base06: '#f5f5f5',
                base07: '#ffffff',
                base08: '#fb0120',
                base09: '#fc6d24',
                base0A: '#fda331',
                base0B: '#a1c659',
                base0C: '#76c7b7',
                base0D: '#6fb3d2',
                base0E: '#d381c3',
                base0F: '#be643c',

                value: {
                  fontSize: '12px',
                },
                label: {
                  fontSize: '12px',
                },
                valueLabel: {
                  fontSize: '12px',
                },
                fontSize: '12px',
                valueText: { fontSize: '12px' },
                nestedNodeLabel: ({ style }, keyPath, nodeType, expanded) => ({
                  style: {
                    fontSize: '12px',
                    value: {
                      fontSize: '12px',
                    },
                    label: {
                      fontSize: '12px',
                    },
                  },
                }),
              }}
              invertTheme={true}
              shouldExpandNodeInitially={() => {
                return false
              }}
            />
          </ScrollArea>
        )

      case 'StringifiedJSON':
        return createJSObjectDisplay(JSON.stringify(JSON.parse(dataObject.text), null, 2) || dataObject.text)

      case 'JSObject':
        return (
          <ScrollArea type="auto" scrollbarSize={4} h={'80vh'}>
            <CopyButton value={JSON.stringify(dataObject.value)} timeout={1200}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck style={{ width: rem(16) }} /> : <IconCopy style={{ width: rem(16) }} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>

            <JSONTree
              data={dataObject.value}
              theme={{
                scheme: 'bright',
                author: 'chris kempson (http://chriskempson.com)',
                base00: '#000000',
                base01: '#303030',
                base02: '#505050',
                base03: '#b0b0b0',
                base04: '#d0d0d0',
                base05: '#e0e0e0',
                base06: '#f5f5f5',
                base07: '#ffffff',
                base08: '#fb0120',
                base09: '#fc6d24',
                base0A: '#fda331',
                base0B: '#a1c659',
                base0C: '#76c7b7',
                base0D: '#6fb3d2',
                base0E: '#d381c3',
                base0F: '#be643c',

                value: {
                  fontSize: '12px',
                },
                label: {
                  fontSize: '12px',
                },
                valueLabel: {
                  fontSize: '12px',
                },
                fontSize: '12px',
                valueText: { fontSize: '12px' },
                nestedNodeLabel: ({ style }, keyPath, nodeType, expanded) => ({
                  style: {
                    fontSize: '12px',
                    value: {
                      fontSize: '12px',
                    },
                    label: {
                      fontSize: '12px',
                    },
                  },
                }),
              }}
              invertTheme={true}
              shouldExpandNodeInitially={() => {
                return true
              }}
            />
          </ScrollArea>
        )
      // return createJSObjectDisplay(JSON.stringify(dataObject.value, null, 2))

      case 'MarkdownText':
      case 'PlainText':
      case 'HtmlContent':
      case 'RunLLMPrompt':
      case 'UserBrief':
        return createMarkdownDisplay(dataObject)

      case 'BroadnError':
        return mapErrorToList(dataObject, 1)

      case 'InputUrl':
      case 'YouTubeUrl':
      case 'GoogleSpreadsheetUrl':
      case 'VideoSubtitle':
        return displayInputType(dataObject)
    }
}
