import cn from 'classnames'
import { IconBuildingSkyscraper } from '@tabler/icons-react'

import css from './Tabs.module.css'

const Tabs = ({ companyList, onChange, active }) => {
  const activeCompany = active.job_company_name || '_'
  return (
    <div className={css.nav}>
      <ul className={css.companies}>
        {companyList.map(({ key, name, list }) => (
          <li className={cn(css.tabs, { [css.activeCompany]: key === activeCompany, [css.noCompany]: !name })} key={key}>
            <div className={cn(css.companyName)}>
              {name && <IconBuildingSkyscraper stroke={1} />} {name}&nbsp;
            </div>
            <div className={css.people}>
              {list.map((item, key) => (
                <button className={cn({ [css.active]: item === active })} key={key} onClick={() => onChange(item)}>
                  {item.full_name || item.firstName + ' ' + item.lastName}
                  {/* <div className={css.jobTitle}>{item.job_title}</div> */}
                </button>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Tabs
