import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import reportsApi from 'api/reports'
import ListItem from './List.item'

import css from './List.module.css'
import { Button } from '@mantine/core'

const List = () => {
  const res = usePreload(() => reportsApi.getList())
  const { data } = res

  return (
    <div className="container">
      <Preload {...res}>
        <header className={css.header}>
          <h1>Events</h1>
          <Button>Create event</Button>
        </header>
        <ul className={css.list}>{data && data.map((item, key) => <ListItem key={key} {...item} />)}</ul>
      </Preload>
    </div>
  )
}

export default List
