import './AllBriefs.scss'
import ServiceContext from 'components/Providers/ServiceContext'
import { useContext, useEffect, useState } from 'react'
import BriefListTable from 'components/BriefsList/BriefListTable'
import { observer } from 'mobx-react'
import { Container } from '@mantine/core'

const AllBriefs = observer((props) => {
  const [allBriefs, setAllBriefs] = useState(null)
  const service = useContext(ServiceContext)

  useEffect(() => {
    service.adminGetAllBriefsForAllUsers().then((res) => {
      setAllBriefs(res)
    })
  }, [])

  if (!allBriefs) return null

  return (
    <Container size="80%">
      <BriefListTable items={allBriefs}></BriefListTable>
    </Container>
  )
})

export default AllBriefs
