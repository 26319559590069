import { useState } from 'react'
import { getPersonRecord } from './PeopleDataLab'

const Test = () => {
  const [name, setName] = useState('mindaugasmazutis')
  const [result, setResult] = useState({})

  const getData = async () => {
    const data = await getPersonRecord(name)
    setResult(data)
  }

  return (
    <div className="container">
      <div>
        <label>linkedIn/</label>
        <input value={name} onChange={({ target }) => setName(target.value)} />
      </div>
      <div>
        <button onClick={() => getData()}>Check</button>
      </div>

      <pre>{JSON.stringify(result, 2, 2)}</pre>
    </div>
  )
}

export default Test
