import AuthContext from 'components/Providers/AuthContext'
import React, { useEffect, useContext, useState, useRef, useCallback } from 'react'
import {
  useLocation,
  Navigate,
} from "react-router-dom";
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

const RequireAuth = observer(({ children }: { children: JSX.Element }) => {
  const auth = useContext(AuthContext)
  let location = useLocation();


  if (auth && !auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
})

export default RequireAuth