import { useState } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { Table, Button, Space, Mark, Stack, ScrollArea, UnstyledButton, Group, Text, Center, TextInput, rem, keys } from '@mantine/core'
import { IconSelector, IconTopologyStar3, IconTorii, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react'
import { formatRelative } from 'date-fns'
import classes from './BriefsList.css'

interface RowData {
  broadnID: string
  subject: string
  from: string
  status: string
  category: string
  lastUpdate: number
  createdAt: number
  threadID: string
  numMessages: number
}

interface ThProps {
  children: React.ReactNode
  reversed: boolean
  sorted: boolean
  w?: string | number | undefined
  ta?: any
  onSort(): void
}

function Th({ children, reversed, sorted, onSort, w, ta }: ThProps) {
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector
  return (
    <Table.Th className={classes.th} w={w} ta={ta}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm" ta={ta}>
            {children}
          </Text>
          {!!onSort ? (
            <Center className={classes.icon}>
              <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
            </Center>
          ) : null}
        </Group>
      </UnstyledButton>
    </Table.Th>
  )
}

function filterData(data: RowData[], search: string) {
  const query = search.toLowerCase().trim()
  const allKeys = keys(data[0])
  if (allKeys.length <= 0) return false
  return data.filter((item) => allKeys.some((key) => item[key] && typeof item[key] !== 'object' && item[key].toString().toLowerCase().includes(query)))
}

function sortData(data: RowData[], payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }) {
  const { sortBy } = payload

  if (!sortBy) {
    return filterData(data, payload.search)
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        if (Number.isNaN(Number(b[sortBy]))) return b[sortBy].toString().localeCompare(a[sortBy].toString())
        else {
          return b[sortBy].toString().localeCompare(a[sortBy].toString())
        }
      }

      if (Number.isNaN(Number(b[sortBy]))) return a[sortBy].toString().localeCompare(b[sortBy].toString())
      else {
        return a[sortBy].toString().localeCompare(b[sortBy].toString())
      }
    }),
    payload.search
  )
}

const mappedData = (data: any[]): RowData[] => {
  return data.map(
    (el: {
      threadMessages: string | any[]
      conversation: string | any[]
      broadnID: any
      subject: any
      from: any
      executionBrief: { status: any; category: any }
      created_at: any
      createdAt: any
    }) => {
      let createdAt: number = el.createdAt ? el.createdAt : 0
      if (createdAt == 0) {
        if (el.threadMessages && el.threadMessages.length > 0) createdAt = el.threadMessages[el.threadMessages.length - 1].timestamp
        if (el.conversation && el.conversation.length > 0) createdAt = el.conversation[el.conversation.length - 1].payload.createdAt
      }
      return {
        broadnID: el.broadnID,
        subject: el.subject,
        from: el.from,
        status: el.executionBrief ? el.executionBrief.status : '',
        category: el.executionBrief ? el.executionBrief.category : '',
        createdAt: el.created_at || el.createdAt,
        lastUpdate: createdAt,
        threadID: '',
        numMessages: el.conversation.length,
      }
    }
  )
}

export default function BriefListTable(props: { items: any }) {
  const [colspan, setColspan] = useState(0)
  const [search, setSearch] = useState('')
  const [sortedData, setSortedData] = useState(mappedData(props.items))
  const [sortBy, setSortBy] = useState<keyof RowData | null>(null)
  const [reverseSortDirection, setReverseSortDirection] = useState(false)

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false
    setReverseSortDirection(reversed)
    setSortBy(field)
    setSortedData(sortData(mappedData(props.items), { sortBy: field, reversed, search }))
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setSearch(value)
    setSortedData(sortData(mappedData(props.items), { sortBy, reversed: reverseSortDirection, search: value }))
  }

  const { items } = props
  if (!items) return null

  const rows = sortedData.map((row: RowData) => {
    return (
      <Table.Tr key={row.broadnID}>
        <Table.Td>
          <Stack gap={0}>
            <Text fz="8" c="gray.6">
              {row.broadnID}
            </Text>
            <Text fz="8" c="gray.6">
              {row.threadID}
            </Text>
          </Stack>
        </Table.Td>
        <Table.Td>
          <Text fz="xs">{row.from}</Text>
        </Table.Td>
        <Table.Td>
          <Text fz="xs" truncate="end" lineClamp={1}>
            {row.subject}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text fz="xs" truncate="end" lineClamp={1}>
            {row.category}
          </Text>
        </Table.Td>
        <Table.Td>
          <Text fz="xs">{row.status}</Text>
        </Table.Td>
        <Table.Td>{row.numMessages}</Table.Td>
        <Table.Td ta={'right'}>
          <Text fz="8" c="gray.6">
            {row.createdAt ? formatRelative(row.createdAt, new Date()) : null}
          </Text>

          <Text fz="8" c="gray.6">
            {row.lastUpdate ? formatRelative(row.lastUpdate, new Date()) : null}
          </Text>
        </Table.Td>

        <Table.Td>
          <Stack gap={0}>
            <Link to={`/admin/briefs/${row.broadnID}`}>
              <Button leftSection={<IconTorii stroke={1} />} variant="subtle" color="pink"></Button>
            </Link>
          </Stack>
        </Table.Td>
        <Table.Td>
          <Link to={`/admin/briefs2/${row.broadnID}`}>
            <Button leftSection={<IconTorii stroke={1} />} variant="subtle" color="pink"></Button>
          </Link>
        </Table.Td>
      </Table.Tr>
    )
  })

  if (colspan == 0 && sortedData && sortedData.length > 0) setColspan(Object.keys(sortedData[0]).length)

  return (
    <ScrollArea>
      <TextInput
        placeholder="Search by any field"
        mb="md"
        leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
        value={search}
        onChange={handleSearchChange}
      />
      <Table verticalSpacing="xs" miw={700} layout="fixed">
        <Table.Tbody>
          <Table.Tr>
            <Th sorted={sortBy === 'broadnID'} reversed={reverseSortDirection} onSort={() => setSorting('broadnID')}>
              IDs
            </Th>
            <Th sorted={sortBy === 'from'} reversed={reverseSortDirection} onSort={() => setSorting('from')}>
              Email
            </Th>
            <Th sorted={sortBy === 'subject'} reversed={reverseSortDirection} onSort={() => setSorting('subject')}>
              Subject
            </Th>
            <Th w={120} sorted={sortBy === 'category'} reversed={reverseSortDirection} onSort={() => setSorting('category')}>
              Category
            </Th>
            <Th w={100} sorted={sortBy === 'status'} reversed={reverseSortDirection} onSort={() => setSorting('status')}>
              Status
            </Th>
            <Th w={100} sorted={sortBy === 'numMessages'} reversed={reverseSortDirection} onSort={() => setSorting('numMessages')}>
              Msgs#
            </Th>
            <Th ta={'right'} w={110} sorted={sortBy === 'createdAt'} reversed={reverseSortDirection} onSort={() => setSorting('createdAt')}>
              Dates
            </Th>
            <Th ta={'right'} w={30} sorted={sortBy === 'createdAt'}>
              !!!
            </Th>
          </Table.Tr>
        </Table.Tbody>
        <Table.Tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <Table.Tr>
              <Table.Td colSpan={colspan}>
                <Text fw={300} ta="center">
                  Nothing found for <Mark c="orange.8">'{search}'</Mark>
                </Text>
                <Text ta="center">
                  If '{search}' is a brief ID then go to it's page
                  <Link to={`/admin/brief/${search}`}>
                    <Button leftSection={<IconTopologyStar3 stroke={1} />} variant="subtle" color="pink">
                      {search}
                    </Button>
                  </Link>
                </Text>
                <Space h="xl" />
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </ScrollArea>
  )
}
