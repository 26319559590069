import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import { toJS } from 'mobx'
import mixpanel from 'mixpanel-browser'
import { observer } from 'mobx-react'

import App from './App'

import Layout from 'layout/Layout'

import Login from './components/Login/Login'

import screens from 'screens'

import auth from 'api/auth'

mixpanel.init('', { debug: window.APP_CONFIG.version === '0.0.0' })

const filterByRoles = (list, userRoles) =>
  list.filter((route) => {
    const { permissions, children } = route
    let valid = true

    if (permissions) {
      valid = !!(userRoles && [].concat(permissions).find((r) => userRoles.includes(r)))
    }

    if (valid && children) {
      route.children = filterByRoles(children, userRoles)
    }

    return valid
  })

const Routes = () => {
  const user = toJS(auth.user)

  const children = filterByRoles(screens, user?.['roles'])

  const router = createBrowserRouter([
    {
      path: '/login',
      element: <App mixpanel={mixpanel} public={true} />,
      errorElement: <App mixpanel={mixpanel} public={true} />,
      children: [
        {
          path: '/login',
          element: <Login />,
        },
      ],
    },
    {
      path: '/',
      element: <Layout />,
      errorElement: <Layout />,
      children,
    },
    {
      path: '/dev',
      element: <App mixpanel={mixpanel} />,
      errorElement: <App mixpanel={mixpanel} />,
      children: children.map((item) => ({ ...item, path: '/dev' + item.path })),
    },
    {
      path: '*',
      element: <Navigate to="/login" />,
      errorElement: <Layout />,
    },
  ])

  return <RouterProvider router={router} />
}

export default observer(Routes)
