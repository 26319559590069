import get from 'lodash.get'

import DateIcon from '../Icons/DateIcon'
import RoleIcon from '../Icons/RoleIcon'
import EducationIcon from '../Icons/EducationIcon'

const UserInfo = ({ person }) => {
  const { firstName, lastName } = person
  const { full_name = firstName + ' ' + lastName, job_title, job_company_name } = person

  const list = [
    { Icon: DateIcon, label: 'With ' + job_company_name, name: 'job_start_date' },
    {
      Icon: RoleIcon,
      label: 'Previous Role',
      name: 'experience.0',
      render: ({ company, title }) => (
        <div>
          <span className="capitalize">{title.name}</span> at <span className="capitalize">{company.name}</span>
        </div>
      ),
    },
    {
      Icon: EducationIcon,
      label: 'Background',
      name: 'education.0',
      render: ({ degrees, majors, school }) => {
        const major = majors?.join(', ') || ''
        const where = school?.name || ''
        const [degree = ''] = degrees

        return (
          <div>
            {degree && (
              <span>
                <span className="capitalize">{degree}</span> in{' '}
              </span>
            )}
            {major} {where && ' from ' + where}
          </div>
        )
      },
    },
  ]
  return (
    <div className="section__person">
      <h2 className="person-name">{full_name}</h2>
      <ul>
        <li className="job-title">{job_title}</li>
        {list.map(({ Icon, label, name, render }, key) => {
          const value = get(person, name)

          return value ? (
            <li key={key} className="list-item">
              {Icon && <Icon />} <b>{label}:</b> {render ? render(value) : value}
            </li>
          ) : null
        })}
      </ul>
    </div>
  )
}

export default UserInfo
