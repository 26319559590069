import { NavLink as Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import { NavLink } from '@mantine/core'
import { IconGauge, IconWallet, IconHome2, IconUsersGroup, IconSparkles, IconReportAnalytics } from '@tabler/icons-react'

import auth from 'api/auth'

import UserCard from './UserCard/UserCard'

const LayoutNav = () => {
  const user = auth.user
  const { roles } = user || {}
  const isAdmin = roles && roles.includes('admin')

  return (
    <div className="layout__nav">
      {!user ? (
        <div className="main-nav">
          <NavLink component={Link} to="/" leftSection={<IconHome2 stroke={1} />} label={'Home'} color="green" />
        </div>
      ) : (
        <div className="user-nav">
          <NavLink component={Link} to="/" leftSection={<IconHome2 stroke={1} />} label={'Dashboard'} color="violet" />

          {isAdmin && <NavLink component={Link} to="/reports" leftSection={<IconGauge stroke={1} />} label={'Your reports'} color="violet" />}

          <NavLink component={Link} to="/billing" leftSection={<IconWallet stroke={1} />} label={'Billing'} color="violet" />

          {/* <NavLink component={Link} to="/test" leftSection={<IconWallet stroke={1} />} label={'Test'} color="violet" /> */}
        </div>
      )}

      {!!user && (
        <div className="main-nav">
          <NavLink component={Link} to="/battle-cards" leftSection={<IconReportAnalytics stroke={1} />} label="Battle cards" color="blue" />
        </div>
      )}

      {isAdmin && (
        <div className="admin-nav">
          {/* <NavLink component={Link} to="/admin/allbriefs" leftSection={<IconSparkles stroke={1} />} label={'All Briefs'} color="orange" /> */}
          <NavLink component={Link} to="/admin/briefs" leftSection={<IconSparkles stroke={1} />} label={'All Briefs'} color="orange" />

          <NavLink component={Link} to="/admin/allusers" leftSection={<IconUsersGroup stroke={1} />} label={'All Users'} color="orange" />
        </div>
      )}
      <UserCard />
    </div>
  )
}

export default observer(LayoutNav)
