import './App.scss'
import buttonClasses from './common/Buttons.css'
import groupsClasses from './common/Groups.css'
import '@mantine/core/styles.css'
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { Outlet, useSearchParams, NavLink, Link, useLoaderData, useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import authService from './api/auth'
import backendService from './api/backend'
import AuthContext from './components/Providers/AuthContext'
import ServiceContext from './components/Providers/ServiceContext'
import { MantineProvider, Button, Group, createTheme } from '@mantine/core'
import { isProductionEnv } from './components/Common'
import AppShellResponsive from './AppShell'
import 'reflect-metadata'
import RequireAuth from 'components/Providers/RequiredAuth'

const App = observer((props) => {
  const auth = useMemo(() => authService, [])
  const backend = useMemo(() => backendService, [])
  const location = useLocation()
  if (location && location.pathname && !['/', '/login'].includes(location.pathname)) {
    localStorage.setItem('loginTarget', location.pathname)
  }

  // const navigate = useNavigate()
  // const [user, setUser] = useState(toJS(auth.user))

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']")
    if (!link) {
      link = document.createElement('link')
      link.rel = 'icon'
      document.getElementsByTagName('head')[0].appendChild(link)
    }
    if (!isProductionEnv()) {
      link.href = '/Wanda-nobgmax.png'
    }
  }, [])

  if (props.public) {
    return (
      <AuthContext.Provider value={auth}>
        <ServiceContext.Provider value={backend}>
          <MantineProvider
            theme={createTheme({
              fontFamily: 'Inter, IBM Plex mono, sans-serif',
              defaultRadius: '8px',
              shadows: {
                md: '1px 1px 3px rgba(0, 0, 0, .25)',
                xl: '5px 5px 3px rgba(0, 0, 0, .25)',
              },
              components: {
                Button: Button.extend({
                  classNames: buttonClasses,
                }),
                Group: Group.extend({
                  classNames: groupsClasses,
                }),
              },
            })}
          >
            <ErrorBoundary fallback={<p>Something went wrong when loading.</p>}>
              <div className="App">
                <AppShellResponsive />
              </div>
            </ErrorBoundary>
          </MantineProvider>
        </ServiceContext.Provider>
      </AuthContext.Provider>
    )
  }

  return (
    <AuthContext.Provider value={auth}>
      <ServiceContext.Provider value={backend}>
        <RequireAuth>
          <MantineProvider
            theme={createTheme({
              fontFamily: 'Inter, IBM Plex mono, sans-serif',
              defaultRadius: '8px',
              shadows: {
                md: '1px 1px 3px rgba(0, 0, 0, .25)',
                xl: '5px 5px 3px rgba(0, 0, 0, .25)',
              },
              components: {
                Button: Button.extend({
                  classNames: buttonClasses,
                }),
                Group: Group.extend({
                  classNames: groupsClasses,
                }),
              },
            })}
          >
            <ErrorBoundary fallback={<p>Something went wrong when loading.</p>}>
              <div className="App">
                <AppShellResponsive />
              </div>
            </ErrorBoundary>
          </MantineProvider>
        </RequireAuth>
      </ServiceContext.Provider>
    </AuthContext.Provider>
  )
})

export default App
