import { useState } from 'react'
import { Badge, Title, Group, Select, Button, Input, TextInput, InputWrapper, MultiSelect, SegmentedControl } from '@mantine/core'
import { useForm } from '@mantine/form'

const EditForm = ({ firstName, lastName, email, roles, accountStatus, onCancel, onSubmit }) => {
  const form = useForm({
    initialValues: {
      firstName,
      lastName,
      email,
      roles: roles || [],
      accountStatus,
    },
  })

  return (
    <form className="edit-user__form" onSubmit={form.onSubmit(onSubmit)}>
      <InputWrapper required label="Account status">
        <div>
          <SegmentedControl
            color="blue"
            {...form.getInputProps('accountStatus')}
            data={[
              { label: 'Active', value: 'active' },
              { label: 'Payment Required', value: 'paymentRequired' },
              { label: 'Blocked', value: 'blocked' },
            ]}
          />
        </div>
      </InputWrapper>
      <TextInput required label="First name" placeholder="First name" disabled {...form.getInputProps('firstName')} />

      <TextInput required label="Last name" placeholder="Last name" disabled {...form.getInputProps('lastName')} />

      <TextInput required label="Email" placeholder="Your email" disabled {...form.getInputProps('email')} />

      <MultiSelect
        color="orange.8"
        defaultValue={roles}
        data={[
          { label: 'User', value: 'user', disabled: true },
          { label: 'Broadn', value: 'broadn' },
          { label: 'Admin', value: 'admin' },
        ]}
        label="Roles"
        placeholder="Pick roles"
        {...form.getInputProps('roles')}
      />

      <div className="edit-user__form__actions">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">Save</Button>
      </div>
    </form>
  )
}

export default EditForm
