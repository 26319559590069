import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IconChevronLeft } from '@tabler/icons-react'

import './BattleCard.scss'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import reportsApi from 'api/reports'

import CompanyInfo from './CompanyInfo/CompanyInfo'
import TalkingPoints from './TalkingPoints/TalkingPoints'
import PersonInfo from './PersonInfo/PersonInfo'
import News from './News/News'
import Tabs from './Tabs/Tabs'

const groupPeople = (data) => {
  const byCompanyName = data?.people.reduce((acc, item) => {
    const key = item.job_company_name || '_'

    acc[key] = acc[key] || []
    acc[key].push(item)
    return acc
  }, {})

  const companyList = Object.keys(byCompanyName)
    .sort()
    .reverse()
    .map((key) => ({ key, name: byCompanyName[key][0]?.job_company_name, company: byCompanyName[key][0]?.company, list: byCompanyName[key] }))

  return { ...data, companyList }
}

const BattleCard = () => {
  const params = useParams()
  const { id } = params
  const [active, setActive] = useState()
  const res = usePreload(async () => {
    const data = await reportsApi.getOne(id)
    return groupPeople(data)
  })

  const { data } = res
  const { companyList, title } = data || {}

  const activePerson = active || (companyList && companyList[0] && companyList[0].list[0])

  return (
    <div className="container">
      <Preload {...res}>
        {data && (
          <div className="company-card">
            <header className="company-card__header">
              <Link to="/battle-cards">
                <IconChevronLeft />
              </Link>
              <h1 className="person-name">{title}</h1>
              <div className="company-card__time">
                <time>Aug 22, 2024 | 10:00 AM PDT</time>
                <div>Join Zoom</div>
              </div>
            </header>
            <section className="company-card__content">
              <Tabs companyList={companyList} active={activePerson} onChange={setActive} />
              <div className="company-card__info">
                <article>
                  <PersonInfo person={activePerson} />
                  <CompanyInfo company={activePerson?.company} />
                </article>
                <article>
                  <TalkingPoints />
                  <News />
                </article>
              </div>
            </section>
          </div>
        )}
      </Preload>
    </div>
  )
}

export default BattleCard
