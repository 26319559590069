import { marked } from 'marked'
import { Container, Tabs, Space, Divider, Card, Stack, Accordion, Group, Text, Code, ScrollArea, Center } from '@mantine/core'
import { IconCloudComputing, IconMessageCircle, IconSettingsAutomation, IconStars, IconStackPop } from '@tabler/icons-react'
import {
  createDisplayForObject,
  createErrorSection,
  createJSObjectDisplay,
  createMarkdownDisplay,
  createRefSection,
  displayInputType,
  flattenObject,
  objectPropsAsList,
} from '../DisplayUtils'
import './WFNodeType.css'

marked.use({ async: false, pedantic: false, gfm: true })

export default function NodePanelForTaskDef(params: any) {
  if (!params) return

  const { selectedNodeComponent, taskContext } = params
  if (!selectedNodeComponent) return
  const { id, data } = selectedNodeComponent
  if (!id || !data) return null

  const features: [string, any][] = []
  const content: any[] = []

  if (['Left', 'BroadnError'].includes(data.output.outputType)) {
    content.push([
      <Accordion.Item value={'name'} key={1}>
        <Accordion.Control>
          <Group justify="flex-start" gap="xl" wrap="nowrap">
            <IconStars stroke={1} color="pink" />
            <Text>{data.name}</Text>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>{createErrorSection(data.errorList, false)}</Accordion.Panel>
      </Accordion.Item>,
    ])
  } else {
    if (data.taskDef && data.taskDef.outputProp) {
      content.push([
        <Accordion.Item value={'out'} key={2}>
          <Accordion.Control>
            <Group justify="flex-start" gap="xl" wrap="nowrap">
              <IconStackPop stroke={1} color="pink" />
              <Text>Output prop: {data.taskDef.outputProp}</Text>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>{createDisplayForObject(taskContext[data.taskDef.outputProp])}</Accordion.Panel>
        </Accordion.Item>,
      ])

      const kvProps = Object.entries(data.taskDef).filter(([k, v]) => !['contextToInputMapping', 'extraInput'].includes(k))
    }
  }

  let suffix: any = null
  if (data.taskDef.contextToInputMapping) {
    const cimFeatures: [string, any][] = []
    const inputMapping = {}
    data.taskDef.contextToInputMapping.forEach(
      (el) => (inputMapping[`from: ${el.fromKey}`] = `to: ${el.toKey}${el.default ? ` / ${el.default.value || JSON.stringify(el.default)}` : ''}`)
    )

    flattenObject(inputMapping, cimFeatures)
    const cimFeaturesCard = cimFeatures.map((feat, index) => (
      <Stack gap={0} key={index} className="groupWBorder">
        <Text size="xs" c="gray.7">
          {feat[0]}
        </Text>
        <Text size="xs" c="var(--mantine-color-anchor)">
          {feat[1]}
        </Text>
      </Stack>
    ))

    if (cimFeaturesCard && cimFeaturesCard.length > 0) {
      content.push(
        <Accordion.Item value={'inputMapping'} key={'inputMapping'}>
          <Accordion.Control>
            <Group justify="flex-start" gap="xl" wrap="nowrap">
              <IconSettingsAutomation stroke={1} color="pink" />
              <Text>Input Mapping</Text>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Group gap={8} mb={0}>
              {cimFeaturesCard}
            </Group>
          </Accordion.Panel>
        </Accordion.Item>
      )
    }
  }
  if (data.taskDef) {
    switch (data.name) {
      case 'RunFunctionTask':
      case 'RunAIModel':
      case 'RunLLMPrompt':
      default:
        flattenObject(
          {
            ...data.taskDef,
            extraInput: undefined,
            contextToInputMapping: undefined,
            text: undefined,
            prompt: null,
            _tag: undefined,
            name: undefined,
          },
          features
        )

        const knownStep = features.find((f) => ['promptBuilderFunction', 'function'].includes(f[0]))
        if (knownStep) {
          suffix = (
            <>
              <Text span fw="200">
                {' : '}
              </Text>
              <Text span fw="500">
                {knownStep[1]}
              </Text>
            </>
          )
        }
        const featuresCards = features.map((feat, index) => (
          <Stack gap={0} key={index} className="groupWBorder">
            <Text size="xs" c="gray.7">
              {feat[0]}
            </Text>
            <Text size="xs" c="var(--mantine-color-anchor)">
              {feat[1]}
            </Text>
          </Stack>
        ))

        if (features && features.length > 0) {
          content.push(
            <Accordion.Item value={'params'} key={'params'}>
              <Accordion.Control>
                <Group justify="flex-start" gap="xl" wrap="nowrap">
                  <IconSettingsAutomation stroke={1} color="pink" />
                  <Text>Parameters</Text>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Group gap={8} mb={0}>
                  {featuresCards}
                </Group>
              </Accordion.Panel>
            </Accordion.Item>
          )
        }

        if (data.taskDef.prompt) {
          content.push(
            <Accordion.Item value={'llmPrompt'} key={'llmPrompt'}>
              <Accordion.Control>
                <Group justify="flex-start" gap="xl" wrap="nowrap">
                  <IconCloudComputing stroke={1} color="pink" />
                  <Text>LLM Prompt</Text>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>{createMarkdownDisplay(data.taskDef)}</Accordion.Panel>
            </Accordion.Item>
          )
        }
        break

      // default:
      //   if (data.input)
      //     content.push(
      //       <Accordion.Item value={'att-input'} key={5}>
      //         <Accordion.Control>
      //           <Group justify="flex-start" gap="xl" wrap="nowrap">
      //             <IconSettingsAutomation stroke={1} color="pink" />
      //             <Text>Input : {data.input._tag}</Text>
      //           </Group>
      //         </Accordion.Control>
      //         <Accordion.Panel>
      //           <Group gap={8} mb={0} className="groupWBorder">
      //             {createDisplayForObject(data.input)}
      //           </Group>
      //         </Accordion.Panel>
      //       </Accordion.Item>
      //     )
    }
  }
  return (
    <Container fluid>
      <Stack gap={'xs'}>
        <Space h={'md'}></Space>
        <Text ta={'center'}>
          {data.name} {suffix}
        </Text>

        <Accordion chevronPosition="right" variant="contained">
          {content}
        </Accordion>
        {createErrorSection(data.errorList, false)}
        <Divider />
        {createRefSection(data.ref, false)}
        <Divider />
        {createDisplayForObject(taskContext)}
      </Stack>
    </Container>
  )
}
