// import './EditConfig .scss'
import ServiceContext from 'components/Providers/ServiceContext'
import AuthContext from 'components/Providers/AuthContext'
import Spinner from 'components/Spinner/Spinner'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useForm } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { NavLink, Title, Loader, CopyButton, Avatar, Table, Text, JsonInput, Container, Space, Button, Group, Stack, Select } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'
import dayjs from 'dayjs'

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

const EditConfig = observer((props) => {
  const service = useContext(ServiceContext)
  const auth = useContext(AuthContext)
  const loggedUser = toJS(auth.user)
  const [configItems, setConfigItems] = useState([])
  const [selectedConfig, setSelectedConfig] = useState(null)
  const [configDict, setConfigDict] = useState({})
  const [loading, { toggle, close }] = useDisclosure()

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: { configKey: '', configValue: '' },
    enhanceGetInputProps: (payload) => {
      return {}
    },
  })

  useEffect(() => {
    service.getGlobalConfig().then((config) => {
      const configItems = config.map((c) => ({ label: c.name, value: c._key }))
      setConfigItems(configItems)
      const dict = {}
      config.forEach((el) => (dict[el._key] = el))
      setConfigDict(dict)
    })
  }, [])

  const handleSubmit = async (values) => {
    toggle()
    const withSetValues = { ...configDict[values.configKey], ...JSON.parse(values.configValue) }
    await service.setGlobalConfig(withSetValues)
    await delay(200)
    close()
  }

  const handleSelectionChange = async (_value, option) => {
    const newVal = { ...configDict[_value], _key: null, _rev: null, _id: null }
    delete newVal._id
    delete newVal._key
    delete newVal._rev
    const safeValue = JSON.stringify(newVal, null, 2)

    setSelectedConfig(_value)

    form.setValues({ configKey: _value, configValue: safeValue })
  }

  return (
    <>
      <Group justify="center" grow>
        <Stack  justify="center" style={{ minWidth: '720px', maxWidth: '80%', paddingTop: '2em' }}>
          <Space h="xl" />
          <Title order={3}>Edit config</Title>
          <Text c="red" fw={600}>Make sure you know what you're doing before changing anything... </Text>
          <form
            onSubmit={form.onSubmit(handleSubmit)}
            style={{ width: '100%', justifyContent: 'flex-end', flexDirection: 'column', display: 'flex' }}
          >
            <Select
              color="pink.9"
              style={{ outline: 'violet' }}
              label="Select config key"
              placeholder="Select config key"
              maxDropdownHeight="320"
              searchable
              data={configItems}
              key={form.key('configKey')}
              {...form.getInputProps('configKey')}
              onChange={handleSelectionChange}
            />

            <JsonInput
              label="Config"
              placeholder="Config object"
              validationError="Invalid JSON"
              key={form.key('configValue')}
              formatOnBlur
              autosize
              minRows={5}
              size="xs"
              {...form.getInputProps('configValue')}
            />
            <Space h="xs" />
            <Group>
              <Button
                variant="outline"
                color="grape.9"
                w="200px"
                type="submit"
                rightSection={loading ? <Loader size="xs" color="grape" type="bars" /> : null}
              >
                {loading ? 'Saving ...' : 'Save to db'}
              </Button>
            </Group>
          </form>
        </Stack>
      </Group>
      <Space h="xl" />
    </>
  )

  return (
    <Container size="80%">
      <Space h="xl" />

      <div>Edit config</div>
    </Container>
  )
})

export default EditConfig
