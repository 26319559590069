const BriefUrls = ({ executionBrief }) => {
  const { weburls = [] } = executionBrief || {}

  return (
    <div>
      <h2>Web URL's ({weburls?.length})</h2>
      <ol className="brief__urls">
        {weburls.map((item, key) => (
          <li key={key}>
            <div className="desc">{item.description}</div>
            <a href="">{item.url}</a>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default BriefUrls
