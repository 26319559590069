import { Link } from 'react-router-dom'
import { IconEye } from '@tabler/icons-react'
import dayjs from 'dayjs'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import backend from 'api/backend'

import './Reports.scss'

const Reports = () => {
  const res = usePreload(backend.adminGetAllBriefsForAllUsers)

  const list = res.data || []

  const columns = [
    {
      id: 'createdAt',
      title: 'Created',
      render: (date) => <time dateTime={dayjs(date).format()}>{date ? dayjs(date).fromNow() : 'N/A'}</time>,
    },
    {
      id: 'from',
      title: 'From',
    },
    {
      id: 'subject',
      title: 'Subject',
    },
    {
      id: 'executionBrief.category',
      title: 'Category',
    },
    {
      id: 'conversation.length',
      title: 'Msgs',
      align: 'center',
    },
    {
      id: 'broadnID',
      align: 'center',
      render: (broadnID) => (
        <Link to={'/reports/' + broadnID}>
          <IconEye size="1rem" stroke={1} /> view
        </Link>
      ),
    },
  ]

  return (
    <div className="container">
      <Preload {...res}>
        <h2>Reports ({list.length})</h2>
        <Table columns={columns} data={list} sticky rowKey="_key" />
      </Preload>
    </div>
  )
}

export default Reports
