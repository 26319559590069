import axios from 'axios'

const baseURL = 'http://localhost:8529/_db/battlecards_public_kb/battlecards_public_kb'
const http = axios.create({ baseURL })
http.interceptors.response.use((response) => response.data)

class ReportsApi {
  getList = () => http.get('/battle-cards')

  getOne = (id) => http.get('/battle-cards/' + id)

  create = () => {}
}

const reportsApi = new ReportsApi()
export default reportsApi
