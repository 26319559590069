import { NavLink, Timeline, Text } from '@mantine/core'
import { IconChevronsLeft, IconSend, IconMailUp } from '@tabler/icons-react'
import dayjs from 'dayjs'
import Markdown from 'react-markdown'

import './Conversations.scss'

const Conversations = ({ conversation = [] }) => {
  return (
    <div className="conversations">
      <Timeline active={1} reverseActive bulletSize={38} lineWidth={2}>
        {[...conversation].reverse().map((item, key) => (
          <Message {...item} key={key} />
        ))}
      </Timeline>
    </div>
  )
}

const Message = ({ id, payload: { executionBrief, taskContextRef, html, headers, plainText = {}, createdAt, location, _tag, json } }) => {
  const text = executionBrief?.brief || json?.Brief?.text || json?.triageIntroHerbieMessage?.text
  const date = createdAt || headers?.date || taskContextRef?.createdAt
  const Icon = html ? IconSend : IconMailUp

  return (
    <Timeline.Item bullet={<Icon size={22} />} title={_tag || 'New branch'}>
      <div className="message"> {html ? <div dangerouslySetInnerHTML={{ __html: html.html }} /> : <Markdown className="markdown">{text}</Markdown>}</div>

      <Text size="xs" mt={4}>
        <time dateTime={dayjs(date).format()}>{date ? dayjs(date).fromNow() : 'N/A'}</time>
      </Text>
    </Timeline.Item>
  )
}

export default Conversations
