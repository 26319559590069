import './Login.scss'
import GmailIcon from '../../icons/Gmail'
import MicrosoftIcon from '../../icons/MicrosoftLogo'
import ServiceContext from 'components/Providers/ServiceContext'
import AuthContext from 'components/Providers/AuthContext'
import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { useForm } from '@mantine/form'
import { jwtDecode } from 'jwt-decode'
import {
  Checkbox,
  Group,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Switch,
  JsonInput,
  Alert,
  Modal,
  Badge,
  Button,
  Image,
  Grid,
  Card,
  Stack,
  Title,
  GridCol,
  Space,
  Anchor,
} from '@mantine/core'
import { IconEye, IconChevronLeft, IconInfoCircle, IconDotsVertical, IconLockAccessOff, IconStarsOff, IconAt, IconLock } from '@tabler/icons-react'

function getFormInitialValues(formType, companionToken) {
  let jwtDec
  if (companionToken) {
    jwtDec = jwtDecode(companionToken)
  }

  let formVals

  if (['resetPassword'].includes(formType)) {
    formVals = { email: '' }
  }
  if (['register'].includes(formType)) {
    formVals = { email: '', termsOfService: false }
  }

  if (formType === 'register-EmailValidated') {
    formVals = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '', termsOfService: false }
  }

  if (formType === 'login') {
    formVals = { email: '', password: '' }
  }

  if (formType === 'resetPassword-EmailValidated') {
    formVals = { email: '', password: '' }
  }
  if (!!jwtDec && jwtDec.email) {
    formVals['email'] = jwtDec.email
  }
  return formVals
}

const validators = {
  firstName: (value) => (value.trim().length >= 2 && value.trim().length < 30 ? null : 'Invalid first name. Length (min 2, max 30 chars)'),
  lastName: (value) => (value.trim().length >= 2 && value.trim().length < 30 ? null : 'Invalid last name. Length (min 2, max 30 chars)'),
  email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
  password: (value) => (value.trim().length >= 8 && value.trim().length < 301 ? null : `Invalid password length (min 8, max 300 chars). Currently ${value.length}`),
  confirmPassword: (value, values) => (value === values.password ? null : 'Passwords do not match'),
  termsOfService: (value) => (value ? null : 'You must agree to our Terms and Conditions.'),
}

function getFormValidators(formType) {
  const keys = Object.keys(getFormInitialValues(formType)).filter((k) => validators[k])
  return Object.fromEntries(keys.map((k) => [k, validators[k]]))
}

const Login = observer((props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useContext(AuthContext)
  const backend = useContext(ServiceContext)
  const loggedUser = toJS(auth.user)
  const [formType, setFormType] = useState('login')
  const [loading, setLoading] = useState(false)
  const [companionToken, setCompanionToken] = useState(null)
  const [invalidEmailProps, setInvalidEmailProps] = useState(null)
  const [error, setError] = useState(null)
  const [info, setInfo] = useState(null)
  const { noShadow, noPadding, noSubmit, style } = props

  const onAccessWithGoogle = async () => {
    await auth.continueWithGoogle()
  }

  const onAccessWithMicrosoft = () => auth.continueWithMicrosoft()

  const navigateHomeIfKnownUser = () => {
    if (loggedUser && loggedUser.roles) {
      if (false && target.pathname) {
        return navigate(target.pathname)
      } else return navigate('/')
    }
  }

  const toggleFormType = (formKind) => {
    setError(null)
    setInfo(null)
    setFormType(formKind)
  }

  let { target } = location.state || { target: { pathname: '/' } }
  let urlParams = new URLSearchParams(location.search)
  const token = urlParams.get('token')
  const resetPasswordToken = urlParams.get('resetPasswordToken')
  const emailVerificationToken = urlParams.get('emailVerificationToken')

  target = { pathname: localStorage.getItem('loginTarget') }

  let form = useForm({
    mode: 'uncontrolled',
    initialValues: getFormInitialValues(formType),
    validate: getFormValidators(formType),
  })

  const handleSubmit = async (formData) => {
    setLoading(true)
    const { email, password, confirmPassword, firstName, lastName, termsOfService, companionToken } = formData
    switch (formType) {
      case 'login':
        await auth.login({ email, password }).then((res) => {
          if (res && !res.success) {
            setError('No user with this email and password exists.')
            setInfo(null)
          }
        })
        navigateHomeIfKnownUser()
        break

      case 'resetPassword':
        await auth.resetPassword({ email }).then((res) => {
          if (res && !res.success) {
            setError(res.data ? res.data.message : res.message)
            setInfo(null)
          } else {
            setInfo('Check your email for further instructions.')
            setError(null)
            form.reset()
          }
        })
        break

      case 'resetPassword-EmailValidated':
        await auth.resetPassword({ email, password, confirmPassword, companionToken }).then((res) => {
          if (res && !res.success) {
            setError(res.data.message || res.message)
            setInfo(null)
          } else {
            form.reset()
            setInfo('Password changed. Try to login now.')
            setError(null)
          }
        })
        navigateHomeIfKnownUser()
        break

      case 'register':
        await auth.signupWithCredentials({ email, termsOfService }).then((res) => {
          if (res && res.success) {
            setError(null)
            setInfo(res.message)
            form.reset()
          } else {
            setError(res.data ? res.data.message : res.message)
            setInfo(null)
          }
        })
        break

      case 'register-EmailValidated':
        await auth.signupWithCredentials({ companionToken, email, password, confirmPassword, termsOfService, firstName, lastName }).then((res) => {
          if (res && !res.success) {
            setError(res.data.message || res.message)
            setInfo(null)
          } else {
            setInfo(res.message)
            setError(null)
          }
          navigateHomeIfKnownUser()
        })
        break
    }
    setLoading(false)
  }

  useEffect(() => {
    navigateHomeIfKnownUser()
  }, [auth.user])

  useEffect(() => {
    if (token) {
      const tokenProps = jwtDecode(token)
      if (tokenProps && tokenProps.newUser) {
        if (tokenProps.isAllowedEmailDomain) {
          return auth.requestGAuth4GCalendar(token)
        }
        if (!invalidEmailProps) setInvalidEmailProps(tokenProps)
        return
      }

      auth.setToken(token).then((user) => {
        if (target.pathname) navigate(target.pathname)
        return navigateHomeIfKnownUser()
      })
    }
    if (resetPasswordToken) {
      setCompanionToken(resetPasswordToken)
      form.setFieldValue('companionToken', resetPasswordToken)
      setFormType('resetPassword-EmailValidated')
    }
    if (emailVerificationToken) {
      setCompanionToken(emailVerificationToken)
      form.setFieldValue('companionToken', emailVerificationToken)
      setFormType('register-EmailValidated')
    }
  }, [])

  useEffect(() => {
    const newVals = getFormInitialValues(formType, companionToken)
    if (!newVals['email']) {
      newVals['email'] = form.values['email'] || ''
    }
    form.setInitialValues(newVals)
    form.setValues(newVals)
  }, [formType])

  if (invalidEmailProps) {
    return (
      <>
        <Stack align="center" justify="center" gap={0}>
          <Stack style={{ maxWidth: 1180, paddingTop: '4em' }}>
            <div style={{ h: '100px', paddingBottom: '2rem' }}>
              <Image radius="md" h={'100px'} fit="contain" src="./AgentHerbieVStack.png"></Image>
            </div>

            <Stack align="center" className="centerColumn" p={0}>
              <Paper
                key={formType}
                p={'md'}
                shadow={'sm'}
                style={{
                  width: '100%',
                  // position: 'relative',
                  // backgroundColor: 'var(--mantine-color-body)',
                }}
              >
                <Text span>
                  {invalidEmailProps.firstName}, the @{invalidEmailProps.webDomain} domain is not supported.
                </Text>
                <Space h={'sm'}></Space>
                <Text>Herbie is currently focusing on custom domains were we can deliver maximum value. Please try again with your work email address.</Text>
              </Paper>
            </Stack>
            <Group justify="left">
              <Button
                justify="left"
                leftSection={<IconChevronLeft size={14} stroke={1} />}
                variant="default"
                mt="md"
                onClick={(event) => {
                  return setInvalidEmailProps(null)
                }}
              >
                Back to login
              </Button>
            </Group>
          </Stack>
        </Stack>
      </>
    )
  }

  return (
    <>
      <Stack align="center" justify="center" gap={0}>
        <Stack style={{ maxWidth: 1180, paddingTop: '4em' }}>
          <div style={{ h: '100px', paddingBottom: '2rem' }}>
            <Image radius="md" h={'100px'} fit="contain" src="./AgentHerbieVStack.png"></Image>
          </div>
          <Stack align="center" gap="md" p={0}>
            <Title order={2} fw={400}>
              Team up with Agent Herbie
            </Title>
            <Title order={4} fw={400}>
              Run Due Dilligence for your meeting attendees
              <br />
            </Title>
            <Title order={4} fw={500}>
              Spot opportunities, gain leverage, mitigate bad karma
            </Title>
          </Stack>

          <Stack align="center" className="centerColumn" p={0}>
            <Paper
              key={formType}
              p={'md'}
              shadow={'sm'}
              style={{
                width: '100%',
                // position: 'relative',
                // backgroundColor: 'var(--mantine-color-body)',
              }}
            >
              <form onSubmit={form.onSubmit(handleSubmit)} key={formType}>
                <LoadingOverlay visible={loading} />

                {formType === 'register-EmailValidated' && (
                  <Group grow>
                    <TextInput data-autofocus required placeholder="Your first name" label="First name" {...form.getInputProps('firstName')} />
                    <TextInput required placeholder="Your last name" label="Last name" {...form.getInputProps('lastName')} />
                  </Group>
                )}

                {['resetPassword', 'register', 'login', 'resetPassword-EmailValidated', 'register-EmailValidated'].includes(formType) && (
                  <TextInput
                    mt="md"
                    required
                    placeholder="Your email"
                    label="Email"
                    key={form.key('email')}
                    leftSection={<IconAt size={16} stroke={1.5} />}
                    {...form.getInputProps('email')}
                    disabled={['resetPassword-EmailValidated', 'register-EmailValidated'].includes(formType)}
                  />
                )}

                {['register-EmailValidated', 'login', 'resetPassword-EmailValidated'].includes(formType) && (
                  <PasswordInput
                    mt="md"
                    required
                    placeholder="Password"
                    label="Password"
                    leftSection={<IconLock size={16} stroke={1.5} />}
                    {...form.getInputProps('password')}
                  />
                )}
                {['register-EmailValidated'].includes(formType) && (
                  <PasswordInput
                    mt="md"
                    required
                    label="Confirm Password"
                    placeholder="Confirm password"
                    leftSection={<IconLock size={16} stroke={1.5} />}
                    {...form.getInputProps('confirmPassword')}
                  />
                )}

                {['register', 'register-EmailValidated'].includes(formType) && (
                  <Checkbox
                    mt="xl"
                    variant="outline"
                    color="green"
                    radius="xs"
                    label=<Text size="sm">
                      I agree to with the <Anchor href={'https://agentherbie.com/terms'}>terms of service</Anchor> of Agent Herbie.
                    </Text>
                    {...form.getInputProps('termsOfService', { type: 'checkbox' })}
                  />
                )}

                {error && (
                  <Text c="red" size="sm" mt="sm">
                    {error}
                  </Text>
                )}
                {info && (
                  <Text c="indigo.7" size="sm" mt="sm">
                    {info}
                  </Text>
                )}

                <Group justify="space-between" mt="md">
                  {formType !== 'login' ? (
                    <Anchor component="button" type="button" c="blue.2" onClick={() => toggleFormType('login')} size="sm">
                      Login
                    </Anchor>
                  ) : null}

                  {!['register', 'register-EmailValidated'].includes(formType) ? (
                    <Anchor component="button" type="button" c="blue.2" onClick={() => toggleFormType('register')} size="sm">
                      Register
                    </Anchor>
                  ) : null}

                  {!['resetPassword', 'resetPassword-EmailValidated'].includes(formType) ? (
                    <Anchor component="button" type="button" c="blue.2" onClick={() => toggleFormType('resetPassword')} size="sm">
                      Reset Password
                    </Anchor>
                  ) : null}

                  <Button color="#2b3245" variant="filled" type="submit">
                    {['register', 'register-EmailValidated'].includes(formType) ? 'Register' : formType === 'login' ? 'Login' : 'Reset password'}
                  </Button>
                </Group>
              </form>
            </Paper>
          </Stack>

          <Stack align="center" className="centerColumn" gap="md">
            <Button className="ssoProviderButton" onClick={onAccessWithGoogle}>
              <GmailIcon />
              <div>Continue with Google account</div>
            </Button>

            <Button className="ssoProviderButton" onClick={onAccessWithMicrosoft}>
              <MicrosoftIcon />
              <div>Continue with Microsoft account</div>
            </Button>

            <Group className="termsAndConditions">
              <Text size="xs">
                {' '}
                By continuing with SSO access offered by the above providers, you agree to AgentHerbie's{' '}
                <Anchor href="https://agentherbie.com/terms" target="_blank">
                  Terms of Service
                </Anchor>{' '}
                and{' '}
                <Anchor href="https://agentherbie.com/privacy-policy" target="_blank">
                  Privacy Policy
                </Anchor>
                .
              </Text>
            </Group>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
})
export default Login
