import { marked } from 'marked'
import {
  Container,
  Button,
  Tabs,
  Space,
  Divider,
  Progress,
  Card,
  Stack,
  Accordion,
  Group,
  Text,
  Code,
  ScrollArea,
  Center,
  CopyButton,
  ActionIcon,
  TextInput,
  Tooltip,
  rem,
  keys,
} from '@mantine/core'
import {
  IconCloudComputing,
  IconMessageCircle,
  IconPhoto,
  IconSettingsAutomation,
  IconStars,
  IconCopy,
  IconCheck,
  IconTopologyStar3,
  IconTorii,
} from '@tabler/icons-react'
import {
  createDisplayForObject,
  createErrorSection,
  createMarkdownDisplay,
  createRefSection,
  displayInputType,
  flattenObject,
  objectPropsAsList,
} from '../DisplayUtils'
import './WFNodeType.css'

marked.use({ async: false, pedantic: false, gfm: true })

export default function NodePanel(params) {
  if (!params) return
  const { id, data } = params
  if (!id || !data) return null
  const features: [string, any][] = []

  const content: any[] = []

  if (['Left', 'BroadnError'].includes(data.output.outputType)) {
    content.push([
      <Accordion.Item value={'out'} key={1}>
        <Accordion.Control>
          <Group justify="flex-start" gap="xl" wrap="nowrap">
            <IconStars stroke={1} color="pink" />
            <Text>Output: {data.output.outputType}</Text>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>{createErrorSection(data.errorList, false)}</Accordion.Panel>
      </Accordion.Item>,
    ])
  } else {
    content.push([
      <Accordion.Item value={'out'} key={1}>
        <Accordion.Control>
          <Group justify="flex-start" gap="xl" wrap="nowrap">
            <IconStars stroke={1} color="pink" />
            <Text>Output: {data.output.outputType}</Text>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>{createDisplayForObject(data.output)}</Accordion.Panel>
      </Accordion.Item>,
    ])
  }

  let suffix: any = null

  if (data.task) {
    switch (data.task.name) {
      case 'RunLLMPrompt':
        flattenObject({ ...data.task, text:undefined, prompt: null, _tag: undefined, name: undefined }, features)

        const knownStep = features.find((f) => f[0] == 'promptBuilderFunction')
        if (knownStep) {
          suffix = (
            <>
              <Text span fw="200">
                {' : '}
              </Text>
              <Text span fw="500">
                {knownStep[1]}
              </Text>
            </>
          )
        }
        const featuresCards = features.map((feat, index) => (
          <Stack gap={0} key={index} className="groupWBorder">
            <Text size="xs" c="gray.7">
              {feat[0]}
            </Text>
            <Text size="xs" c="var(--mantine-color-anchor)">
              {feat[1]}
            </Text>
          </Stack>
        ))

        if (features && features.length > 0) {
          content.push(
            <Accordion.Item value={'params'} key={2}>
              <Accordion.Control>
                <Group justify="flex-start" gap="xl" wrap="nowrap">
                  <IconSettingsAutomation stroke={1} color="pink" />
                  <Text>Parameters</Text>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Group gap={8} mb={0}>
                  {featuresCards}
                </Group>
              </Accordion.Panel>
            </Accordion.Item>
          )
        }

        if (data.task.prompt) {
          content.push(
            <Accordion.Item value={'llmPrompt'} key={3}>
              <Accordion.Control>
                <Group justify="flex-start" gap="xl" wrap="nowrap">
                  <IconCloudComputing stroke={1} color="pink" />
                  <Text>LLM Prompt</Text>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>{createMarkdownDisplay(data.task)}</Accordion.Panel>
            </Accordion.Item>
          )
        }
        break

      default:
        if (data.input)
          content.push(
            <Accordion.Item value={'att-input'} key={2}>
              <Accordion.Control>
                <Group justify="flex-start" gap="xl" wrap="nowrap">
                  <IconSettingsAutomation stroke={1} color="pink" />
                  <Text>Input : {data.input._tag}</Text>
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <Group gap={8} mb={0} className="groupWBorder">
                  {createDisplayForObject(data.input)}
                </Group>
              </Accordion.Panel>
            </Accordion.Item>
          )
    }
  }
  return (
    <Container fluid>
      <Stack gap={'xs'}>
        <Space h={'md'}></Space>
        <Text ta={'center'}>
          {data.task.name} {suffix}
        </Text>

        <Accordion chevronPosition="right" variant="contained">
          {content}
        </Accordion>
        {createErrorSection(data.errorList, false)}
        <Divider />
        {createRefSection(data.ref, false)}
      </Stack>
    </Container>
  )
}
