import { Link } from 'react-router-dom'
import { IconBuildingSkyscraper, IconUser } from '@tabler/icons-react'
import { Button } from '@mantine/core'
import cn from 'classnames'

import backendApi from 'api/backend'

import css from './List.module.css'

const ListItem = ({ _key, company, people, title }) => {
  const execute = async () => {
    const res = await backendApi.executeBattleCard(_key)
    console.log(res)
  }

  return (
    <li className={css.item}>
      <h3>
        <Link to={'/battle-cards/' + _key}>{title}</Link>
        <Button size="compact-sm" variant="light" onClick={execute}>
          Execute
        </Button>
      </h3>
      <div className={css.details}>
        {!!company.length && (
          <div className={css.companies}>
            <h4>Company</h4>

            {company.map((item, key) => (
              <div key={key} className={css.company}>
                <div>
                  <IconBuildingSkyscraper size="3rem" stroke={1} />
                </div>
                <div>
                  <div className={css.title}>{item.display_name}</div>
                  <p>{item.headline}</p>
                </div>
              </div>
            ))}
          </div>
        )}

        {!!people.length && (
          <div className={css.people}>
            <h4>People</h4>

            {people.map((item, key) => (
              <div key={key} className={cn(css.person, css[item.status])}>
                <div className={css.icon}>
                  <IconUser size="2rem" stroke={1} />
                </div>
                <div>
                  <div className={css.title}>{item.full_name || item.firstName + ' ' + item.lastName}</div>
                  {!item.full_name && <p>{item.status || '...'}</p>}
                  <p>{item.job_title}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </li>
  )
}

export default ListItem
