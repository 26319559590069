import { graphFromTaskDef } from 'screens/Brief/Workflow/Utils'

import backend from 'api/backend'

export const filterWorkflows = (conversation) => {
  return conversation.reduce((buf, { payload, id }) => {
    const { _tag, taskDefRef, taskContextRef, version } = payload || {}

    let last = buf.at(-1)
    let workflow = { id, version, refData: {}, resolvedData: {} }

    if (['S3RefTaskDef', 'S3RefTaskContext'].includes(_tag)) {
      if (!last || (last.refData.taskDef && last.refData.taskContext)) buf.push({ ...workflow, type: 'onboarding' })

      last = buf.at(-1)
      if (last && _tag === 'S3RefTaskDef') last.refData.taskDef = payload
      if (last && _tag === 'S3RefTaskContext') last.refData.taskContext = payload
    }

    if (_tag === 'WorkflowExecutionResult') {
      buf.push({ ...workflow, refData: { taskDef: taskDefRef, taskContext: taskContextRef }, type: 'wf2ExecRun' })
    }
    return buf
  }, [])
}

export const getTabData = async (broadnID, tab) => {
  const [taskDef, taskContext] = await Promise.all([
    backend.getBriefResultFile(broadnID, tab.refData?.taskDef?.location),
    tab.refData?.taskContext?.location && backend.getBriefResultFile(broadnID, tab.refData?.taskContext?.location),
  ])
  tab.graph = graphFromTaskDef(taskDef)
  tab.resolvedData = { taskDef, taskContext }
  return tab
}
