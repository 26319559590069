import './TransactionsTable.scss'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { compareAsc, format } from 'date-fns'
import { Table, Group, Text, Badge, Menu, ActionIcon, rem } from '@mantine/core'
import { IconEye, IconDotsVertical, IconLockAccessOff, IconStarsOff } from '@tabler/icons-react'

function formatDateTSPretty(timestamp) {
  if (!timestamp) return ''
  if (timestamp.toString().length < 13) {
    timestamp = timestamp * 1000
  }
  return format(new Date(timestamp), 'dd MMM yyyy HH:mm:ss')
}

function letterCasing(s = '') {
  if (!s) return s
  return [s[0].toUpperCase(), s.slice(1).toLowerCase()].join('')
}

const createStripeOptsMenu = (props) => {
  const cancelSubscription = (suscriptionID, paymentIntent) => {
    if (props.cancelSubscription) {
      props.cancelSubscription(suscriptionID, paymentIntent)
    }
  }
  return (
    <Menu withinPortal position="bottom-end" shadow="sm" key={props.tx._key}>
      <Menu.Target>
        <ActionIcon variant="subtle" color="gray" p={0} m={0}>
          <IconDotsVertical stroke={1} style={{ width: rem(16), height: rem(16) }} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item leftSection={<IconEye stroke={1} style={{ width: rem(14), height: rem(14) }} />}>
          <a href={props.tx.invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            View Invoice
          </a>
        </Menu.Item>

        <Menu.Item
          color="red"
          leftSection={<IconStarsOff stroke={1} style={{ width: rem(14), height: rem(14) }} />}
          onClick={() => cancelSubscription(props.tx.invoice.lines.data[0].subscription, props.tx._key)}
        >
          Cancel Stripe subscription
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const createGiftOptsMenu = (key, adminMode, removeGiftMembership) => {
  if (!adminMode) return null
  return (
    <Menu withinPortal position="bottom-end" shadow="sm" key={key}>
      <Menu.Target>
        <ActionIcon variant="subtle" color="gray" p={0} m={0}>
          <IconDotsVertical stroke={1} style={{ width: rem(16), height: rem(16) }} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          color="red"
          leftSection={<IconLockAccessOff stroke={1} style={{ width: rem(14), height: rem(14) }} />}
          onClick={() => removeGiftMembership(key)}
        >
          Remove gift membership
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const TransactionsTable = (
  { transactions, activeMembership, removeGiftMembership, cancelSubscription, adminMode } = { transactions: [], adminMode: false }
) => {
  useEffect(() => {}, [transactions])

  return (
    <Table stickyHeader stickyHeaderOffset={0} withTableBorder className="TransactionsTable">
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Date & Time</Table.Th>
          <Table.Th>Product</Table.Th>
          <Table.Th>Type</Table.Th>
          <Table.Th>Price</Table.Th>
          <Table.Th>Status</Table.Th>
          <Table.Th>Membership Duration</Table.Th>
          <Table.Th style={{ textAlign: 'right' }}> Options</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Array.from(transactions).map((transaction) => {
          const activeBadge =
            compareAsc(new Date(transaction.invoice.lines.data[0].period.end * 1000), new Date()) > 0 && transaction.status != 'refunded' ? (
              <Badge variant="light" color="green" size="xs" radius="xs">
                Active
              </Badge>
            ) : null

          return (
            <Table.Tr key={transaction._id}>
              <Table.Td>{formatDateTSPretty(transaction.createdAt)}</Table.Td>
              <Table.Td>
                {transaction.invoice.lines.data[0].description}
                {activeBadge}
              </Table.Td>
              <Table.Td>{letterCasing(transaction.invoice.lines.data[0].plan.product)}</Table.Td>
              <Table.Td>${(transaction.invoice.lines.data[0].amount / 100).toFixed(2)}</Table.Td>
              <Table.Td>{transaction.status}</Table.Td>
              <Table.Td>
                {formatDateTSPretty(transaction.invoice.lines.data[0].period.start)} -{' '}
                {formatDateTSPretty(transaction.invoice.lines.data[0].period.end)} ({transaction.invoice.lines.data[0].plan.interval})
              </Table.Td>
              <Table.Td>
                <Group justify="flex-end" m={0} p={0} gap={0}>
                  {transaction.status === 'gifted' ? (
                    <>
                      <Text size="xs" c="orange">
                        Gifted
                      </Text>
                      {createGiftOptsMenu(transaction._key || '', adminMode, removeGiftMembership)}
                    </>
                  ) : null}
                  {transaction.status === 'paid' ? <>{createStripeOptsMenu({ tx: transaction, cancelSubscription })}</> : null}{' '}
                </Group>
              </Table.Td>
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
  )
}

export default TransactionsTable
