import cn from 'classnames'
import { formatDistance } from 'date-fns'
import { Badge } from '@mantine/core'

const BriefHeader = ({ subject, broadnID, from, createdAt, conversation, version, executionBrief }) => {
  const createdDate = createdAt || new Date()
  const { status } = executionBrief || {}
  const statusColor = { confirmed: 'lime' }

  return (
    <div className="brief__header">
      <div className="brief__header__left">
        <div>
          <b>{subject}</b>
        </div>
        <div>{from}</div>
        <div className="brief__header__id">{broadnID}</div>
      </div>
      <div>
        <div>
          Last action: <time>{formatDistance(createdDate, new Date(), { addSuffix: true })}</time>
        </div>
        <div>
          Created: <time>{formatDistance(createdDate, new Date(), { addSuffix: true })}</time>
        </div>
      </div>
      <div>
        <div>Messages: {conversation?.length}</div>
        <div>Version: {version}</div>
      </div>
      <div>
        <div>
          <Badge color={statusColor[status]} variant="light">
            {status}
          </Badge>
        </div>
      </div>
    </div>
  )
}

export default BriefHeader
