const TalkingPoints = () => {
  return (
    <div className="section__talking-points">
      <h2>Talking points</h2>
      <ul>
        <li>
          <b>Build Trust:</b>
          <p>
            Both have published in NeurIPS: Arthur on 'Stochastic Optimization for Large-scale Optimal Transport' (2016) and Dharmesh on 'Predicting Customer Churn in
            HubSpot using ML' (2022).
          </p>
        </li>
        <li>
          <b>Ask About:</b>
          <p>Mistral's approach to fine-tuning large language models for specific business domains, particularly in CRM and marketing contexts.</p>
        </li>
        <li>
          <b>Comment On</b>
          <p>Arthur's recent keynote at ICML 2024 on 'Responsible AI Development in Startups', drawing parallels to HubSpot's AI ethics guidelines.</p>
        </li>
      </ul>
    </div>
  )
}

export default TalkingPoints
