import { useState } from 'react'
import cn from 'classnames'
import { Tabs } from '@mantine/core'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

import { filterWorkflows, getTabData } from './BriefWorkflow.utils'
import BriefWorkflowGraph from './BriefWorkflow.graph'
import backend from 'api/backend'

import TaskDefNode from 'screens/Brief/Workflow/TaskDefNodeTypes'

import { createDisplayForObject } from '../../../Brief/DisplayUtils'

const BriefWorkflow = ({ broadnID, conversation }) => {
  const [selected, setSelected] = useState()

  const res = usePreload(() => filterWorkflows(conversation))
  const json = usePreload(() => null)

  const { data } = res || {}
  const { graph = {}, resolvedData = {} } = json?.data || {}

  return (
    <div className="brief__workflow">
      <Preload {...res}>
        {data && (
          <Tabs
            // value={tabName}
            onChange={(value) => {
              json.onUpdate(() => getTabData(broadnID, data[value]))
            }}
          >
            <Tabs.List justify="flex-end">
              {data.map((tab, key) => (
                <Tabs.Tab key={key} value={key + ''}>
                  {tab.type}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
        )}

        <Preload {...json}>
          <div className="brief__workflow__content">
            {selected && <aside>{resolvedData.taskContext ? createDisplayForObject(resolvedData.taskContext) : 'No taskContext data!'}</aside>}
            <BriefWorkflowGraph {...json} onChange={({ nodes }) => setSelected(nodes[0])} />
          </div>
        </Preload>
      </Preload>
    </div>
  )
}

export default BriefWorkflow
