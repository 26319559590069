import { useState } from 'react'
import dayjs from 'dayjs'
import { Select, Button } from '@mantine/core'

//-----------------------------------------------------------------------------------------------
const availableMemberships = [
  {
    group: 'free',
    items: [{ label: '1 month Free', value: '1:free' }],
  },
  {
    group: 'basic',
    items: [
      { label: '1 month Basic', value: '1:basic' },
      { label: '2 months Basic', value: '2:basic' },
      { label: '3 months Basic', value: '3:basic' },
    ],
  },
  {
    group: 'premium',
    items: [
      { label: '1 month Premium', value: '1:premium' },
      { label: '2 months Premium', value: '2:premium' },
      { label: '3 months Premium', value: '3:premium' },
    ],
  },
]

//-----------------------------------------------------------------------------------------------
const createMembership = (selectedVal, user) => {
  if (!selectedVal || !user) return null
  const [months, type] = selectedVal.split(':').map((s) => s.trim())
  const selectedMembershipGroup = availableMemberships.find((m) => m.group.toLowerCase() == type.toLowerCase())

  let selectedMembership
  if (!selectedMembershipGroup) return null
  selectedMembership = selectedMembershipGroup.items.find((m) => m.value == selectedVal)

  if (!selectedMembership) return null

  const amount = 0
  const description = `1 × ${selectedMembership.label} - gifted by Admin`
  const refStartDate = Date.now()
  const start = Math.floor(refStartDate / 1000)
  const end = Math.floor(dayjs(refStartDate).add(months, 'month').toDate().getTime() / 1000)

  return {
    amount,
    description,
    currency: 'usd',
    status: 'gifted',
    createdAt: refStartDate,
    user: {
      email: user.email,
      displayName: user.displayName,
      uid: user.uid,
    },
    invoice: {
      lines: {
        data: [
          {
            amount,
            currency: 'usd',
            description,
            period: { start, end },
            plan: { interval: 'month', interval_count: months, product: type.toLowerCase() },
            price: { recurring: { interval: 'month', interval_count: months }, product: type.toLowerCase() },
          },
        ],
      },
    },
  }
}
//-----------------------------------------------------------------------------------------------

const CreateMembership = ({ onSubmit, user }) => {
  const [selected, setSelected] = useState()

  return (
    <div className="edit-user__membership">
      <Select
        value={selected}
        label="Select membership plan"
        placeholder="Pick membership"
        maxDropdownHeight="320"
        data={availableMemberships}
        onChange={(val) => setSelected(val)}
      />
      <Button
        variant="outline"
        color="grape.9"
        onClick={() => {
          setSelected(null)
          onSubmit(createMembership(selected, user))
        }}
      >
        Add selected
      </Button>
    </div>
  )
}

export default CreateMembership
