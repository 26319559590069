// See https://github.com/peopledatalabs/peopledatalabs-js
import PDLJS from 'peopledatalabs'

// Create a client, specifying your API key
const PDLClient = new PDLJS({ apiKey: '2cbce894c5c9e95e869d0a0cab370c48bfb3013f0ddd01d7b286c3d5816f576f' })

// Chaining Implementation
// PDLClient.person
//   .enrichment({ profile: 'linkedin.com/in/victor-para' })
//   .then((response) => {
//     // Print the API response in JSON format
//     console.log(response?.data)
//   })
//   .catch((error) => {
//     console.log(error)
//   })

// Async/Await Implementation
export const getPersonRecord = async (name) => {
  const response = await PDLClient.person.enrichment({ profile: 'linkedin.com/in/' + name })
  // Print the API response in JSON format
  console.log(response?.data)
  return response?.data
}

// try {
//   const getPersonRecord = async () => {
//     const response = await PDLClient.person.enrichment({ profile: 'linkedin.com/in/victor-para' })
//     // Print the API response in JSON format
//     console.log(response?.data)
//   }

//   getPersonRecord()
// } catch (error) {
//   console.log(error)
// }
//https://www.linkedin.com/in/mindaugasmazutis/
