import { Link } from 'react-router-dom'
import { IconEye } from '@tabler/icons-react'
import { Badge } from '@mantine/core'
import dayjs from 'dayjs'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'
import Table from 'components/Table/Table'

import backend from 'api/backend'

const colors = { confirmed: 'lime' }

const BriefsList = () => {
  const res = usePreload(backend.adminGetAllBriefsForAllUsers)

  const list = res.data || []

  const columns = [
    {
      id: 'createdAt',
      title: 'Created',
      render: (date) => (
        <div>
          <div>
            <time dateTime={dayjs(date).format()}>{date ? dayjs(date).fromNow() : 'N/A'}</time>
          </div>

          <div>
            <time className="small" dateTime={dayjs(date).format()}>
              {date ? dayjs(date).format('DD/MM/YYYY') : 'N/A'}
            </time>
          </div>
        </div>
      ),
    },
    {
      id: 'from',
      title: 'From',
      render: (value, { from, broadnID }) => (
        <div>
          <div className="title">{from}</div>
          <div className="id">{broadnID}</div>
        </div>
      ),
    },
    {
      id: 'subject',
      title: 'Subject',
    },
    {
      id: 'executionBrief.category',
      title: 'Category',
    },
    {
      id: 'conversation.length',
      title: 'Msgs',
      align: 'center',
    },
    {
      id: 'executionBrief.status',
      title: 'Status',
      align: 'center',
      render: (status) => (
        <Badge color={colors[status]} variant="light">
          {status}
        </Badge>
      ),
    },
    {
      id: 'broadnID',
      align: 'center',
      render: (broadnID) => (
        <Link to={'/admin/briefs/' + broadnID}>
          <IconEye size="1rem" stroke={1} /> V1
        </Link>
      ),
    },
    {
      id: 'broadnID',
      align: 'center',
      render: (broadnID) => (
        <Link className="blue" to={'/admin/briefs-v2/' + broadnID}>
          <IconEye size="1rem" stroke={1} /> V2
        </Link>
      ),
    },
  ]

  return (
    <div className="container">
      <Preload {...res}>
        <h2>Briefs ({list.length})</h2>
        <Table columns={columns} data={list} sticky rowKey="_key" />
      </Preload>
    </div>
  )
}

export default BriefsList
