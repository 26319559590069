import './Billing.scss'
import React, { useEffect, useContext, useState, useRef, useCallback } from 'react'
import { Group, Switch, JsonInput, Alert, Modal, Badge, Button, Image, Grid, Card, Stack, Title, Text, GridCol, Space, Anchor } from '@mantine/core'
import { IconEye, IconInfoCircle, IconDotsVertical, IconLockAccessOff, IconStarsOff } from '@tabler/icons-react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import AuthContext from 'components/Providers/AuthContext'
import ServiceContext from 'components/Providers/ServiceContext'
import PlandDescriptionCard from './PlandDescriptionCard'
import TransactionsTable from 'components/TransactionsTable/TransactionsTable'
import Spinner from 'components/Spinner/Spinner'
import { compareAsc, format } from 'date-fns'
import { useDisclosure } from '@mantine/hooks'
import cn from 'classnames'

function formatDatePretty(timestamp) {
  return format(new Date(timestamp * 1000), 'dd MMM yyyy')
}

function formatDateTSPretty(timestamp) {
  return format(new Date(timestamp), 'dd MMM yyyy')
}

const Billing = observer((props) => {
  const auth = useContext(AuthContext)
  const service = useContext(ServiceContext)
  const [userProfile, setUser] = useState(toJS(auth.user))
  const [userProfileWMembership, setUserWMembership] = useState(null)
  const [scriptInitialized, setScriptInit] = useState(null)
  const [modalOpened, modalOps] = useDisclosure(true)
  const [transactions, setTxs] = useState([])
  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [plans, setPlans] = useState(null)
  const [yearly, setYearly] = useState(false)

  const toggleYearly = () => {
    setYearly(!yearly)
  }

  const cancelSubscriptionModal = async (subscription, paymentIntent) => {
    modalOps.open()
    const tx = transactions.find((ta) => ta._key === paymentIntent)
    setSelectedTransaction(tx)
  }

  const cancelSubscription = async () => {
    if (!selectedTransaction) return

    await service.cancelStripeSubscription(selectedTransaction.invoice.lines.data[0].subscription, selectedTransaction._key).then((res) => {
      modalOps.close()
      service.getUserPayments(true).then((payments) => setTxs(payments))
    })
  }

  // useEffect(() => {
  //   if (!scriptInitialized) {
  //     const script = document.createElement('script')
  //     script.src = 'https://js.stripe.com/v3/buy-button.js'
  //     script.async = true
  //     document.head.appendChild(script)
  //     setScriptInit(true)
  //   }
  //   const newUser = toJS(auth.user)
  //   if (newUser !== userProfile) setUser(newUser)
  // }, [auth, auth.user])

  useEffect(() => {
    if (userProfile && userProfile.uid) {
      service.getMyUserWithMembership().then((userWM) => {
        setUserWMembership(userWM)
      })
      service.getAvailablePlans().then((plans) => setPlans(plans))
      service.getUserPayments().then((payments) => setTxs(payments))
    }
  }, [auth, auth.user])

  if (!userProfile || !plans || !plans.memberships) return <Spinner />

  const interval = yearly ? 'year' : 'month'
  const activeMembership = Array.from(transactions || []).find(
    (ta) =>
      ta.status != 'refunded' && ta.invoice && ta.invoice.lines && compareAsc(new Date(ta.invoice.lines.data[0].period.end * 1000), new Date()) > 0
  )

  let activeMembershipBadge = null
  if (!!activeMembership) {
    const memershipPeriod = activeMembership.invoice.lines.data[0].period
    activeMembershipBadge = (
      <>
        <Stack gap={0}>
          <Badge color="green.8" autoContrast size="xl" variant="light" radius="xs" p={'6px 40px'} mt={'10px'} mb={'10px'}>
            Membership active
          </Badge>
          <Text component="span" size="xs" m={0}>
            Valid until {format(new Date(memershipPeriod.end * 1000), 'd MMMM yyyy HH:mm')}
          </Text>
        </Stack>
      </>
    )
  }

  return (
    <div className="billing">
      {!!selectedTransaction ? (
        <Modal
          opened={modalOpened}
          onClose={modalOps.close}
          title="Stripe Subscription"
          centered
          size="auto"
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          <Alert
            variant="light"
            color="orange"
            title="Are you sure you want to cancel this Stripe subscription?"
            icon={<IconInfoCircle stroke={1} />}
          >
            <Text size="sm">Your subscription will stand valid until the end of the term. Automatic renewal is canceled.</Text>
            <br />
            <Text size="sm">
              <b>Created at:</b> {formatDateTSPretty(new Date(selectedTransaction.createdAt))}
            </Text>
            <Text size="sm">
              <b>Customer Name:</b> {selectedTransaction.invoice.customer_name}
            </Text>
            <Text size="sm">
              <b>Description:</b> {selectedTransaction.invoice.lines.data[0].description}
            </Text>
            <Text size="sm">
              <b>Amount:</b> ${(selectedTransaction.invoice.lines.data[0].amount / 100).toFixed(2)}
            </Text>
            <Text size="sm">
              <b>Status:</b> {selectedTransaction.status}
            </Text>
            <Text size="sm">
              <b>Period:</b> {formatDatePretty(selectedTransaction.invoice.lines.data[0].period.start)} -{' '}
              {formatDatePretty(selectedTransaction.invoice.lines.data[0].period.end)} ({selectedTransaction.invoice.lines.data[0].plan.interval})
            </Text>

            <Space h="lg" />
            <Text size="sm" fw={500}>
              Your subscription will be cancellend and it will not renew automatically. The last day when you can use the service is:{' '}
              {formatDatePretty(selectedTransaction.invoice.lines.data[0].period.end)}.
            </Text>

            <Space h="lg" />
          </Alert>
          <Space h="md" />
          <Group>
            <Button variant="subtle" color="gray" onClick={modalOps.close}>
              No
            </Button>
            <Button variant="subtle" color="gray" onClick={() => cancelSubscription()}>
              Yes, cancel my subscription
            </Button>
          </Group>
          <Space h="md" />
          <Text size="sm" c="gray.6">
            If you have questions or detect issues with your membership please reach out to us at{' '}
            <Anchor href="mailto:billing@agentherbie.com" target="_blank">
              support@agentherbie.com
            </Anchor>
            .
          </Text>
          <Space h="md" />
        </Modal>
      ) : null}

      <Space h="md" />
      <Stack align="center" justify="center">
        <Stack style={{ maxWidth: 1180, paddingTop: '4em' }}>
          <Title order={2}>Billing</Title>

          {false && !!activeMembership ? (
            activeMembershipBadge
          ) : (
            <>
              <Stack>
                <Title order={3}>Upgrade your account</Title>
                <Text>You can use the following payment methods to upgrade your membership or pay for your services.</Text>
              </Stack>
              <Space h="md" />
              <Stack justify="center" align="center" w={'100%'}>
                <Group justify="center">
                  <Switch
                    styles={{ trackLabel: { padding: '6px 10px', fontWeight: 600 } }}
                    size="xl"
                    color="#00bda3"
                    onLabel="Yearly"
                    offLabel="Monthly"
                    checked={yearly}
                    onChange={toggleYearly}
                  />
                </Group>
                <Grid gutter="sm">
                  <Grid.Col span={4}>
                    <div className="PlanCard">
                      <PlandDescriptionCard
                        defaultPlan
                        customer_email={userProfile.email}
                        plan={plans.memberships.find((m) => m.type == 'free')}
                        features={plans.descriptions['free']}
                      />
                    </div>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <div className="PlanCard">
                      <PlandDescriptionCard
                        customer_email={userProfile.email}
                        plan={plans.memberships.find((m) => m.type == 'basic' && m.interval == interval)}
                        features={plans.descriptions['basic']}
                      />
                    </div>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <div className="PlanCard">
                      <PlandDescriptionCard
                        customer_email={userProfile.email}
                        plan={plans.memberships.find((m) => m.type == 'premium' && m.interval == interval)}
                        features={plans.descriptions['premium']}
                      />
                    </div>
                  </Grid.Col>
                </Grid>
              </Stack>
            </>
          )}

          <Space h="xl" />

          <Title order={3}>Your previous transactions</Title>
          {/* 
          <Text size="sm">
            Once you are customer, you can also manage your subscription directly on{' '}
            <Anchor href="https://checkout.broadn.io/p/login/4gw9C02YhcaY2CQ3cc" target="_blank">
              Stripe portal
            </Anchor>
            .
          </Text> 
          */}

          <TransactionsTable
            transactions={transactions}
            activeMembership={activeMembership}
            cancelSubscription={cancelSubscriptionModal}
            adminMode={false}
          />

          <Text size="sm" c="gray.6">
            If you have questions or detect issues with your membership please reach out to us at{' '}
            <Anchor href="mailto:billing@agentherbie.com" target="_blank">
              billing@agentherbie.com
            </Anchor>
          </Text>
          <Space h="md" />
        </Stack>
      </Stack>
    </div>
  )
})

export default Billing
