const capitalizeEachWord = (text) => {
  if (!text) {
    return ''
  }
  return text.split(/\s+/g).map((seg) =>
    seg
      .split(/(?=[A-Z0-9])|(?<=[0-9])/gu)
      .map((w) => `${w[0].toUpperCase()}${w.slice(1)}`)
      .join(' ')
  )
}

function isProductionEnv() {
  return window.APP_CONFIG && window.APP_CONFIG.environment && window.APP_CONFIG.environment.toString().includes('prod')
}
export { capitalizeEachWord, isProductionEnv }
