import { useState, useEffect, useRef } from 'react'

import Spinner from 'components/Spinner/Spinner'

export const usePreload = (asyncFn, key) => {
  const isCalledRef = useRef(false)
  const [getDataFn] = useState(() => asyncFn)

  const [data, setData] = useState()
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(null)
  const [updateFn, setUpdateFn] = useState()

  const onUpdate = (fn) => {
    isCalledRef.current = false
    setUpdateFn(fn)
  }

  useEffect(() => {
    const handlePromise = async () => {
      isCalledRef.current = true
      setIsLoading(true)

      try {
        const result = await (updateFn ? updateFn : getDataFn())
        setData(result)
      } catch (e) {
        console.error('-- ERROR --', e)
        setError(e)
      }

      setIsLoading(false)
    }

    if (!isCalledRef.current) handlePromise()
  }, [key, updateFn, getDataFn])

  return { isLoading, data, error, onUpdate }
}

export const Preload = ({ children, isLoading, data, className }) => {
  if (isLoading === null) return null

  if (isLoading && !data) return <Spinner />

  return (
    <div className={className} style={{ opacity: isLoading ? 0.5 : 1 }}>
      {children}
    </div>
  )
}

export default Preload
