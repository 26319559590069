import * as E from 'fp-ts/Either'

export interface Input<A> {
  _tag: string
  broadnID: string
  ref: string
  input(): A
}
export interface WithErrorList {
  errorList: BroadnError[]
}
export interface Output<A> extends WithErrorList {
  _tag: string
  ref: string
  taskInput?: TaskInputRef
  broadnID: string

  output(): A
  toPlainText(): PlainText
}

export interface TaskInputRef {
  taskName: string
  //actual input as calculated by the combiner function
  actualInput?: Input<any> | Output<any>
  //main input as provided by the TaskDef
  main?: any
  prompt?: any
  // children input as available at task runtime
  children?: { [jobKey: string]: E.Either<BroadnError, any> }
}

export interface TaskOpts {
  ignoreDependencyOnFailure?: boolean
  removeDependencyOnFailure?: boolean
  failParentOnFailure?: boolean
  attempts?: number
  backoff?: any
}

const defaultTaskOpts: TaskOpts = {}

interface TaskDefProps {
  name?: string
  queueName?: string
  data?: any // Consider specifying a more precise type if possible
  opts?: Record<string, unknown> // More specific than 'object'
  children?: TaskDef[]
}

export interface TaskOpts {
  ignoreDependencyOnFailure?: boolean
  removeDependencyOnFailure?: boolean
  failParentOnFailure?: boolean
  attempts?: number
  backoff?: any
}

interface TaskDefProps {
  name?: string
  queueName?: string
  data?: any // Consider specifying a more precise type if possible
  opts?: Record<string, unknown> // More specific than 'object'
  children?: TaskDef[]
}

export default class TaskDef {
  static classname: string = 'TaskDef'
  _tag: string = TaskDef.classname
  name: string
  queueName: string
  data: TaskDefData
  opts?: object
  children?: TaskDef[]

  constructor() {}

  static is(el: any): boolean {
    return el && el._tag && el._tag === this.classname
  }

  static from(props: TaskDefProps): TaskDef {
    const res = new TaskDef()
    return Object.assign(res, props)
  }
}

export interface BroadnError {
  name: string
  message: string
  location?: any
  ref: string
  broadnID: string
  errorList?: BroadnError[]
}

export class PlainText implements Input<string>, Output<string> {
  static _tagName = 'PlainText'
  public _tag: string = PlainText._tagName
  public text: string
  public ref: string
  taskInput?: TaskInputRef
  public broadnID: string
  errorList: BroadnError[]

  constructor(text: string, ref?: string, broadnID?: string) {
    this.text = text
    this.ref = ref || ''
    this.errorList = []
  }

  toPlainText(): PlainText {
    this.text = this.text.trim()
    return this
  }
  output(): string {
    return this.text.trim()
  }

  input(): string {
    return this.text.trim()
  }
}

export class InputUrl implements Input<string> {
  public _tag = 'InputUrl'
  public url: string
  public ref: string
  public broadnID: string = ''

  constructor(url: string) {
    this.url = url
    this.ref = this.url
  }

  input(): string {
    return this.url
  }

  static isTopDomain(input: InputUrl): boolean {
    const url = new URL(input.url)
    if (url.pathname && url.pathname.length > 1) {
      return false
    }
    return true
  }

  static isInputUrl(other: any) {
    if (other && other._tag) {
      return other._tag === 'InputUrl'
    }
    return false
  }
}

export class MarkdownText implements Input<string>, Output<string> {
  public _tag: string = 'MarkdownText'
  public text: string
  public ref: string
  public broadnID: string

  errorList: BroadnError[]

  taskInput?: TaskInputRef

  constructor(text: string, ref?: string) {
    this.text = text
    this.ref = ref || ''
    this.errorList = []
  }
  input(): string {
    return this.text.trim()
  }
  output(): string {
    return this.text.trim()
  }
  toPlainText(): PlainText {
    return new PlainText(this.text.trim(), this.ref)
  }
  setRef(ref: string): MarkdownText {
    this.ref = ref
    return this
  }
  setBroadnID(broadnID: string): MarkdownText {
    this.broadnID = broadnID
    return this
  }
}

export type PropMapping = { fromKey: string; toKey: string; defaultValue?: any }
export type InnerPropSelector = { innerPropSelector?: string }

export interface InputOutputCommand {
  contextToInputMapping: PropMapping[]
  outputProp?: string
}

export type ForkTaskFlowCommand = TaskDefData & {
  outputParentTasks: TaskDef[]
}

export interface TaskDefData {
  extraInput?: TaskContext
  props?: MergeResultsProps & { [k: string]: any }
  broadnID: string
}

export type BuildJsonCommand = TaskDefData & {
  propsMapping: (PropMapping & InnerPropSelector)[]
  outputProp: string
}

export interface InputOutputCommand {
  contextToInputMapping: PropMapping[]
  outputProp?: string
}

export interface HeaderKV {
  name?: string | null
  value?: string | null
}

export class PDFContent {
  _tag = 'PDFContent'
  public pages: { [k: string]: any }
  public result: string
  public ref: string
  public broadnID: string
  errorList: BroadnError[]
}

export interface ParsedHeaders {
  subject: string
  from: string
  date: string
  returnPath: string
  references: string
  inReplyTo: string
  messageID: string
}

export class HtmlContent {
  static tagName: string = 'HtmlContent'
  public _tag: string = HtmlContent.tagName
  public html: string
  public broadnID: string

  public originalUrl: InputUrl

  errorList: BroadnError[]

  public ref: string

  constructor(text: string, ref?: string) {
    this.html = text
    this.ref = ref || ''
    this.errorList = []
  }

  static is(a: any): boolean {
    if (a && a._tag) return a._tag === HtmlContent.tagName
    return false
  }
}

export class User {
  _key: string
  firstName: string
  lastName: string
  displayName: string
  email: string
  username: string
  password: string | null
  createdAt: number
  emailVerified = false
  profileImageUrl: string | null
  meta: { [k: string]: string } = {}
  providers = {}
  roles: string[]
}

export class RawGmailMessage {
  static className = 'RawGmailMessage'
  _tag: string = RawGmailMessage.className
  ref: string
  broadnID: string = ''
  options: { createDraft: boolean; knownThread: boolean; knownUser?: User } = { createDraft: false, knownThread: false }

  errorList: BroadnError[]

  constructor() {}

  gmailID: string
  messageId: string
  threadId: string
  plainText: PlainText

  html: HtmlContent
  attachments: Input<any>[]
  messageParts: Input<any>[]

  // // @Type(() => InputUrl)
  // embeddedLinks: InputUrl[]

  rawHeaders?: HeaderKV[]
  headers: ParsedHeaders

  static is(other: any): boolean {
    return other && other._tag && other._tag === RawGmailMessage.className
  }
}
export type MergeResultsProps = { overwriteOutput?: boolean; skipMatchingProps?: boolean }

export interface WebUrlSource {
  url: string
  description: string
  source: 'user' | 'websearch'
}

export class ExecutionBrief {
  static classname: string = 'ExecutionBrief'
  _tag: string = ExecutionBrief.classname
  status: 'draft' | 'confirmed'
  brief: string
  category: string
  weburls?: WebUrlSource[]
}

export type ConversationItemPayloadType = RawGmailMessage | ExecutionBrief | TaskDef | TaskContext | S3RefTaskDef | S3RefTaskContext
export class ConversationItem {
  static classname: string = 'ConversationItem'
  _tag: string = ConversationItem.classname

  id: string
  payload: any

  constructor() {}

  static fromItem(val: any) {
    const res = new ConversationItem()
    res.payload = val
    return res
  }
}

export type RunFunctionCommand = TaskDefData &
  InputOutputCommand & {
    function: string
  }

export class S3RefTaskDef {
  static classname: string = 'S3RefTaskDef'
  _tag: string = S3RefTaskDef.classname
  location: string
  createdAt: number

  constructor() {
    this.createdAt = new Date().getTime()
  }
  static fromProps(props: {}) {
    const result = new S3RefTaskDef()
    return Object.assign(result, props)
  }

  static is(other: any) {
    return other && other._tag && other._tag === S3RefTaskDef.classname
  }
}

export class S3RefTaskContext {
  static classname: string = 'S3RefTaskContext'
  _tag: string = S3RefTaskContext.classname
  location: string
  createdAt: number

  constructor() {
    this.createdAt = new Date().getTime()
  }

  static fromProps(props: {}) {
    const result = new S3RefTaskContext()
    return Object.assign(result, props)
  }
  static is(other: any) {
    return other && other._tag && other._tag === S3RefTaskContext.classname
  }
}

export class WorkflowExecutionResult {
  static classname: string = 'WorkflowExecutionResult'
  _tag: string = WorkflowExecutionResult.classname
  version: number
  executionBrief: ExecutionBrief
  taskDef?: TaskDef
  taskContext?: TaskContext
  taskDefRef?: S3RefTaskDef
  taskContextRef?: S3RefTaskContext

  static is(other: any): other is WorkflowExecutionResult {
    return !!other && other._tag && other._tag === WorkflowExecutionResult.classname
  }

}

export class TaskContext {
  static classname = 'TaskContext'
  _tag: string = TaskContext.classname;
  [k: string]: any

  errorList: BroadnError[]

  keysList: string[]
  broadnID: string

  constructor() {
    this.keysList = []
    this.errorList = []
  }

  static is(el: any): boolean {
    return el && el._tag && el._tag === this.classname
  }

  setNewResult(key: string, value: Output<any>, overwrite: boolean = false): TaskContext {
    // if (value && TaskContext.is(value)) {
    //   return this.mergeWith(value as TaskContext, overwrite)
    // }

    if (key && value && value.errorList) {
      this.errorList.unshift(...value.errorList)
    }

    if (overwrite) {
      this[key] = value
      if (!this.keysList.includes(key)) this.keysList.push(key)
      return this
    }

    if (key in this) {
      let index = 0
      while (`${key}${index}` in this) {
        index++
      }
      this[`${key}${index}`] = value
      this.keysList.push(`${key}${index}`)
      return this
    }

    this[key] = value
    this.keysList.push(key)
    return this
  }

  mergeWith(value: TaskContext, overwrite: boolean): TaskContext {
    if (value) {
      if (TaskContext.is(value)) {
        value.keysList.forEach((k) => this.setNewResult(k, value[k], overwrite))
      }
      if (value.errorList) {
        this.errorList.unshift(...value.errorList)
      }
    }
    return this
  }

  static reduce(a: TaskContext, b: TaskContext, params: MergeResultsProps) {
    const result = new TaskContext()
    a.keysList.forEach((k) => result.setNewResult(k, a[k]))

    b.keysList
      .filter((k) => !(params.skipMatchingProps && a.keysList.includes(k)))
      .forEach((k) => result.setNewResult(k, b[k], params.overwriteOutput))
    result.setErrors([...(a.errorList || []), ...(b.errorList || [])])
    result.broadnID = a.broadnID || b.broadnID
    return result
  }

  setError(err: BroadnError) {
    if (err) {
      this.errorList.push(err)
    }
    return this
  }

  setErrors(errors: BroadnError[]) {
    if (errors) {
      this.errorList.push(...errors)
    }
    return this
  }

  getLiteral(name: string): any {
    return this[name]
  }

  setBroadnID(broadnID: string) {
    this.broadnID = broadnID
    return this
  }
}
