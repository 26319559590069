import { useParams, Link } from 'react-router-dom'
import { NavLink, Timeline, Text } from '@mantine/core'
import { IconChevronsLeft, IconSend, IconMailUp } from '@tabler/icons-react'
import dayjs from 'dayjs'
import Markdown from 'react-markdown'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

import backend from 'api/backend'

import './Report.scss'

const Report = () => {
  const { reportId } = useParams()
  const res = usePreload(async () => {
    const { brief } = await backend.adminGetBriefFull(reportId)
    const conversation = brief.conversation.filter(({ payload }) => payload._tag !== 'S3RefTaskDef')
    const files = conversation
      .filter(({ payload }) => payload.location)
      .map(({ payload }) => {
        payload.getData = async () => {
          payload.json = await backend.getBriefResultFile(reportId, payload.location)
        }
        return payload
      })

    brief.conversation = conversation

    const fetchAll = files.map(({ getData }) => getData())
    await Promise.all(fetchAll)

    console.log(123, files, brief)
    return { brief }
  })

  const { subject, from, conversation = [] } = res?.data?.brief || {}

  return (
    <div className="report">
      <NavLink component={Link} to="/reports" label="back" leftSection={<IconChevronsLeft size="1rem" stroke={1} />} w={'100px'} />
      <Preload {...res}>
        <div className="container">
          <h2>Report: {subject}</h2>
          <h4>{from}</h4>

          <br />
          <Timeline active={1} reverseActive bulletSize={38} lineWidth={2}>
            {[...conversation].reverse().map((item, key) => (
              <Message {...item} key={key} />
            ))}
          </Timeline>
        </div>
      </Preload>
    </div>
  )
}

const Message = ({ id, payload: { executionBrief, taskContextRef, html, headers, plainText = {}, createdAt, location, _tag, json } }) => {
  const text = executionBrief?.brief || json?.Brief?.text || json?.triageIntroHerbieMessage?.text
  const date = createdAt || headers?.date || taskContextRef?.createdAt
  const Icon = html ? IconSend : IconMailUp

  return (
    <Timeline.Item bullet={<Icon size={22} />} title={_tag || 'New branch'}>
      <div className="message"> {html ? <div dangerouslySetInnerHTML={{ __html: html.html }} /> : <Markdown className="markdown">{text}</Markdown>}</div>

      <Text size="xs" mt={4}>
        <time dateTime={dayjs(date).format()}>{date ? dayjs(date).fromNow() : 'N/A'}</time>
      </Text>
    </Timeline.Item>
  )
}

export default Report
