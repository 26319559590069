import { Navigate } from 'react-router-dom'

import Billing from './Billing/Billing'

import BriefPage from './Brief/Brief'

import Admin from './Admin/Admin'
import Users from './Admin/Users/Users'

import AdminAllUsers from './Admin/AllUsers/AllUsers'
import AdminAllBriefs from './Admin/AllBriefs/AllBriefs'

import EditUser from './Admin/EditUser/EditUser'
import EditConfig from './Admin/EditConfig/EditConfig'

import Dashboard from './Dashboard/Dashboard'
import UserProfile from './User/Profile/Profile'
import Reports from './Reports/Reports'
import Report from './Report/Report'

import AdminBrief from './Admin/Full/Full'
import AdminBriefsList from './Admin/BriefsList/BriefsList'

import BattleCardList from './BattleCard/List/List'
import BattleCard from './BattleCard/BattleCard'
import Test from './Test/Test'

const screens = [
  {
    path: '/',
    element: <Dashboard />,
    permissions: 'user',
  },
  {
    path: '/test',
    element: <Test />,
    permissions: 'user',
  },
  {
    path: '/billing',
    element: <Billing />,
    permissions: 'user',
  },

  {
    path: '/dashboard',
    element: <Dashboard />,
    permissions: 'user',
  },

  {
    path: '/battle-cards',
    element: <BattleCardList />,
    permissions: 'user',
  },

  {
    path: '/battle-cards/:id',
    element: <BattleCard />,
    permissions: 'user',
  },
  {
    path: '/reports',
    element: <Reports />,
    permissions: 'user',
  },
  {
    path: '/reports/:reportId',
    element: <Report />,
    permissions: 'user',
  },
  {
    path: '/user/profile',
    element: <UserProfile />,
    permissions: 'user',
  },

  {
    path: '/admin/briefs/:broadnID',
    element: <BriefPage />,
    permissions: 'admin',
  },
  {
    path: '/admin/briefs',
    element: <AdminBriefsList />,
    permissions: 'admin',
  },
  {
    path: '/admin/briefs-v2/:broadnID',
    element: <AdminBrief />,
    permissions: 'admin',
  },
  {
    path: '/admin/allusers',
    element: <AdminAllUsers />,
    permissions: 'admin',
  },
  {
    path: '/admin',
    element: <Admin />,
    permissions: ['broadn'],
  },
  {
    path: '/admin/users',
    element: <Users />,
    permissions: ['broadn'],
  },
  {
    path: '/admin/users/:userid',
    element: <EditUser />,
    permissions: 'admin',
  },
  {
    path: '/admin/allbriefs',
    element: <AdminAllBriefs />,
    permissions: 'admin',
  },
  {
    path: '/admin/config',
    element: <EditConfig />,
    permissions: 'admin',
  },
  {
    path: '/',
    element: <Navigate to="/login" />,
  },
]

export default screens
