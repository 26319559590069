import { useState } from 'react'
import { useForm } from '@mantine/form'
import { TextInput, Badge, Button } from '@mantine/core'
import { IconPencil } from '@tabler/icons-react'

import auth from 'api/auth'

import './Profile.scss'

const UserProfile = () => {
  const { displayName, email, roles, profileImageUrl, firstName, lastName } = auth.user || {}
  const [edit, setEdit] = useState(false)

  const form = useForm({
    initialValues: {
      firstName,
      lastName,
      email,
      roles: roles || [],
    },
  })

  return (
    <div className="profile container">
      <h2>My profile</h2>

      <div className="profile__box">
        <div className="profile__box__left">
          <div className="avatar">
            <img src={profileImageUrl} alt={displayName} />
          </div>

          <div>
            <div className="title">
              {displayName}

              <button className="btn-link" onClick={() => setEdit(!edit)} disabled={edit}>
                <IconPencil size={16} />
                Edit
              </button>
            </div>
            <div className="email">{email}</div>

            <div className="roles">
              {/* <div className="roles__label">Roles:</div> */}
              <div className="badges">
                {roles &&
                  roles.map((role) => (
                    <Badge key={role} color="gray.3" autoContrast radius="xs">
                      {role}
                    </Badge>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {edit && (
        <div className="profile__details">
          <TextInput required label="First name" placeholder="First name" {...form.getInputProps('firstName')} />

          <TextInput required label="Last name" placeholder="Last name" {...form.getInputProps('lastName')} />

          <div className="actions">
            <Button variant="outline" onClick={() => setEdit(!edit)}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserProfile
