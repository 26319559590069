import './AllUsers.scss'
import { useNavigate } from 'react-router-dom'
import { Avatar, Table, Container, Space, Button } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'
import dayjs from 'dayjs'

import { Preload, usePreload } from 'components/PreLoad/PreLoad'
import backend from 'api/backend'

const AllUsers = (props) => {
  const navigate = useNavigate()

  const resolve = usePreload(backend.adminGetAllUsers)
  const allUsers = resolve.data

  const rows =
    allUsers &&
    allUsers.map((user, index) => {
      return (
        <Table.Tr key={index}>
          <Table.Td>
            <Avatar src={user.profileImageUrl || '/user.jpg'} alt={user.displayName} radius="xl" size={24} w={24} color="violet" variant="subtle" />
          </Table.Td>
          <Table.Td>{user.displayName}</Table.Td>
          <Table.Td>{dayjs(user.createdAt).format('DD-MM-YYYY HH:mm')}</Table.Td>
          <Table.Td>{user.email}</Table.Td>
          <Table.Td>{(user.roles || []).join(', ')}</Table.Td>
          <Table.Td>{Object.keys(user.providers || []).join(', ')}</Table.Td>
          <Table.Td>
            <Button
              leftSection={<IconEye size="1rem" stroke={1} />}
              variant="subtle"
              color="green"
              size="xs"
              onClick={() => navigate(`/admin/users/${user._key || user.uid}`)}
            >
              view
            </Button>
          </Table.Td>
        </Table.Tr>
      )
    })

  return (
    <Preload {...resolve}>
      <Container size="80%">
        <Space h="xl" />
        <Table
          stickyHeader
          stickyHeaderOffset={30}
          horizontalSpacing="sm"
          captionSide="bottom"
          highlightOnHover
          withTableBorder
          style={{ borderRadius: '5px', fontWeight: 300 }}
        >
          <Table.Thead style={{ backgroundColor: '#F5F5F5' }}>
            <Table.Tr>
              <Table.Th>ProfileImage</Table.Th>
              <Table.Th>Full name</Table.Th>
              <Table.Th>Joined</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Roles</Table.Th>
              <Table.Th>Providers</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Container>
    </Preload>
  )
}

export default AllUsers
