import css from './News.module.css'

const list = [
  {
    title: "Mistral AI Releases 'Le Chat' - A Multilingual AI Assistant",
    date: 'July 15, 2024',
    desc: "Mistral AI launches 'Le Chat', an advanced multilingual AI assistant.",
    whyThisMatters: "This release could open up possibilities for integrating advanced language processing capabilities into HubSpot's global CRM solutions.",
  },
  {
    title: 'Mistral AI Secures €200M in Series B Funding',
    date: 'June 3, 2024',
    desc: 'Mistral AI raises €200M in a Series B round, led by Andreessen Horowitz.',
    whyThisMatters:
      "This significant funding could accelerate Mistral AI's development, potentially leading to more robust AI solutions that could be integrated into HubSpot's platform.",
  },
]

const News = () => {
  return (
    <div className="section__news">
      <h2>News that Matter</h2>
      <ul>
        {list.map(({ title, date, desc, whyThisMatters }, key) => (
          <li className={css.item} key={key}>
            <div className={css.title}>{title}</div>
            <time className={css.date}>{date}</time>
            <div className={css.desc}>{desc}</div>
            <div className={css.matters}>
              <b>Why this patters: </b>
              {whyThisMatters}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default News
