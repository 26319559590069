import { observable, action, toJS, autorun, makeObservable } from 'mobx'
import axios from 'axios'
import http from './http'
import app from './app'

const client = http.axios

const auth = {
  user: null,
  init() {
    if (http.validToken) {
      this.me()
    } else {
      this.user = null
    }
    this.tz = new Date().getTime()
    return makeObservable(this, {
      user: observable,
      me: action,
      getMe: action,
      logout: action,
      init: action,
      ts: action,
    })
  },

  ts() {
    return this.tz
  },
  //-------------------------------------------------------------------------------------------
  async logout() {
    this.user = null
    await client.get('/logout')
    http.logout()
  },

  //-------------------------------------------------------------------------------------------
  async signupWithCredentials(formData) {
    try {
      const result = await client.post('/signupWithCredentials', formData)
      if (result && result.success) {
        if (result.followUpNeeded) return result
        if (result.jwt) {
          this.setToken(result.jwt)
          return result
        }
      }
    } catch (error) {
      return { success: false, ...error }
    }
  },

  //-------------------------------------------------------------------------------------------
  async resetPassword(formData) {
    try {
      const result = await client.post('/reset-password', formData)
      if (result.success) {
        if (result.followUpNeeded) return result
        if (result.jwt) {
          this.setToken(result.jwt)
          return result
        }
      }
      return result
    } catch (error) {
      return { sucess: false, ...error }
    }
  },
  //-------------------------------------------------------------------------------------------
  async registerWithCode(code, body) {
    const response = await client.put('/register/' + code, body)
    http.setJwt(response.jwt)

    this.user = response.user
    return response
  },

  //-------------------------------------------------------------------------------------------
  async login(credentials) {
    if (credentials) {
      try {
        const response = await client.post('/login', credentials)
        if (response && response.jwt) {
          return this.setToken(response.jwt)
        }
      } catch (error) {
        return { ...error, success: false }
      }
    }
  },

  async setToken(jwt) {
    http.setJwt(jwt)
    return await auth.me()
  },

  //-------------------------------------------------------------------------------------------
  async continueWithMicrosoft() {
    window.location.replace(`${http.baseURL}/auth/microsoft/`)
  },

  //-------------------------------------------------------------------------------------------
  async continueWithGoogle() {
    window.location.replace(`${http.baseURL}/auth/google/`)
  },
  //-------------------------------------------------------------------------------------------
  requestGAuth4GCalendar(jwt) {
    window.location.replace(`${http.baseURL}/auth/google/wCalendarInit?token=${jwt}`)
  },

  //-------------------------------------------------------------------------------------------
  async hasValidToken() {
    return http.hasValidToken() 
  },

  async getMe() {
    const responseUser = await client.get('/me').catch((e) => {
      console.log('Caught error in getMe', e)
      if ([401, 403].includes(e.status)) {
        console.log('Unauthenticated.Forbidden. Logging out... Error:', e)
        http.logout()
        return null
      }
      return this.user
    })
    if (!responseUser) return null
    this.user = { ...responseUser, profileImageUrl: responseUser.profileImageUrl || '/user.jpg' }
    return responseUser
  },

  async me() {
    const response = await this.getMe()
    if (response) {
      // const isLoginUrl = window.location.pathname.includes('login')
      app.lang = response.lang
    }
    return response
  },

  get isLoggedIn() {
    return this.user && this.user.email
  },
}

auth.init()
window.auth = auth

export default auth
