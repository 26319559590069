import { useState } from 'react'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { compareAsc } from 'date-fns'

import { NavLink, ActionIcon, rem, Menu, Badge, Title, Group } from '@mantine/core'
import { IconLockAccessOff, IconDotsVertical, IconChevronsLeft } from '@tabler/icons-react'

import { Preload, usePreload } from 'components/PreLoad/PreLoad'
import backend from 'api/backend'

import EditForm from './EditForm'
import CreateMembership from './CreateMembership'
import TransactionsTable from 'components/TransactionsTable/TransactionsTable'

import relativeTime from 'dayjs/plugin/relativeTime'

import './EditUser.scss'

dayjs.extend(relativeTime)

const delay = (ms) => new Promise((r) => setTimeout(() => r(ms), ms))

const EditUser = () => {
  const [showEdit, setShowEdit] = useState(false)
  //-----------------------------------------------------------------------------------------------
  const { userid } = useParams()

  //-----------------------------------------------------------------------------------------------
  const getData = async () => {
    const [profile, transactions] = await Promise.all([backend.adminGetUserProfile(userid), backend.adminGetUserTransactions(userid)])
    return { profile, transactions }
  }

  //-----------------------------------------------------------------------------------------------
  const user = usePreload(getData)

  //-----------------------------------------------------------------------------------------------
  const { data, onUpdate } = user
  const { transactions = [], profile } = user?.data || {}
  const { displayName, email, profileImageUrl, createdAt, roles = [], providers = {}, accountStatus } = profile || {}

  //-----------------------------------------------------------------------------------------------
  const activeMembership = transactions.find(
    ({ status, invoice }) =>
      status && status !== 'refunded' && invoice && invoice.lines && compareAsc(new Date(invoice.lines.data[0].period.end * 1000), new Date()) > 0
  )

  //-----------------------------------------------------------------------------------------------
  const addMembership = (membership) => {
    onUpdate(async () => {
      await backend.addGiftMembership(membership)
      const transactions = await backend.adminGetUserTransactions(userid)
      return { ...data, transactions }
    })
  }

  //-----------------------------------------------------------------------------------------------
  const removeGiftMembership = async (_key) => {
    onUpdate(async () => {
      await backend.removeGiftMembership({ _key })
      const transactions = await backend.adminGetUserTransactions(userid)
      return { ...data, transactions }
    })
  }

  //-----------------------------------------------------------------------------------------------
  const updateProfile = ({ roles, accountStatus }) => {
    onUpdate(async () => {
      const profile = await backend.updateProfile(userid, { roles, accountStatus })
      await delay(1000)
      setShowEdit(false)
      return { ...data, profile }
    })
  }

  //-----------------------------------------------------------------------------------------------
  const menuItems = [
    {
      label: 'Block user',
      color: 'red',
      show: accountStatus !== 'blocked',
      onClick: () => updateProfile({ accountStatus: 'blocked' }),
    },
    {
      label: 'Payment required',
      color: 'lime.7',
      show: accountStatus !== 'paymentRequired',
      onClick: () => updateProfile({ accountStatus: 'paymentRequired' }),
    },
    {
      label: 'ReEnable user',
      show: accountStatus !== 'active',
      onClick: () => updateProfile({ accountStatus: 'active' }),
    },
    {
      label: 'Edit',
      show: true,
      onClick: () => setShowEdit(!showEdit),
    },
  ].filter((item) => item.show)

  //-----------------------------------------------------------------------------------------------
  return (
    <div>
      <Group grow>
        <Group>
          <NavLink href="/admin/allusers/" label="back" leftSection={<IconChevronsLeft size="1rem" stroke={1} />} w={'100px'} />
        </Group>
      </Group>
      <Preload {...user} className="edit-user container">
        <div className="edit-user__wrapper">
          <div className="edit-user__profile">
            <div className="edit-user__preview">
              <div className="edit-user__avatar">
                <img src={profileImageUrl || '/user.jpg'} alt="Logo" />
              </div>
              <div className="edit-user__details">
                <div>
                  <div className="title">{displayName}</div>
                  <div className="email">{email}</div>
                </div>

                <div>
                  <Menu withinPortal position="bottom-end" shadow="sm">
                    <Menu.Target>
                      <ActionIcon variant="subtle" color="gray">
                        <IconDotsVertical style={{ width: rem(16), height: rem(16) }} />
                      </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                      {menuItems.map((item) => (
                        <Menu.Item
                          key={item.label}
                          leftSection={<IconLockAccessOff style={{ width: rem(14), height: rem(14) }} />}
                          color={item.color}
                          onClick={item.onClick}
                        >
                          {item.label}
                        </Menu.Item>
                      ))}
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </div>
              <div className="edit-user__footer">
                <div className="edit-user__footer__time">
                  <div>{dayjs(createdAt).fromNow()}</div>
                  <div> {dayjs(createdAt).format('DD MMM YY')}</div>
                </div>

                {accountStatus !== 'active' && (
                  <Badge color="orange.8" autoContrast size="xl" variant="light" radius="xs" p=".5em" mb=".5em" mt=".5em">
                    Account {accountStatus}
                  </Badge>
                )}

                <div className="edit-user__footer__info">
                  <label>Providers:</label>
                  <div className="--badges">
                    {Object.keys(providers).map((item) => (
                      <Badge key={item} color="gray.3" autoContrast size="xs" radius="xs">
                        {item}
                      </Badge>
                    ))}
                  </div>
                </div>

                <div className="edit-user__footer__info">
                  <label>Tags:</label>
                  <div className="--badges">
                    {!!transactions.length && (
                      <Badge color="gray.3" autoContrast size="xs" radius="xs">
                        Txs: {transactions.length}
                      </Badge>
                    )}

                    {activeMembership && (
                      <Badge color="lime" autoContrast size="xs" radius="xs">
                        Paid user
                      </Badge>
                    )}
                  </div>
                </div>

                <div className="edit-user__footer__info">
                  <label>Roles:</label>
                  <div className="--badges">
                    {roles &&
                      roles.map((role) => (
                        <Badge key={role} color="gray.3" autoContrast size="xs" radius="xs">
                          {role}
                        </Badge>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            {showEdit && <EditForm {...profile} onCancel={() => setShowEdit(false)} onSubmit={updateProfile} />}
          </div>
        </div>

        <div className="edit-user__transactions">
          <CreateMembership onSubmit={addMembership} user={profile} />

          <Title order={3}>User's previous transactions</Title>

          <TransactionsTable transactions={transactions} removeGiftMembership={removeGiftMembership} adminMode={true} />
        </div>
      </Preload>
    </div>
  )
}

export default EditUser
