import { useParams } from 'react-router-dom'
import { Tabs, rem } from '@mantine/core'

import Preload, { usePreload } from 'components/PreLoad/PreLoad'

import BriefHeader from './BriefHeader/BriefHeader'
import BriefGeneral from './BriefGeneral/BriefGeneral'
import BriefWorkflow from './BriefWorkflow/BriefWorkflow'
import BriefUrls from './BriefUrls/BriefUrls'
import BriefConversations from './BriefConversations/BriefConversations'

import backend from 'api/backend'

import './Brief.scss'

import MockData from '../../Brief/full.json'

const Full = () => {
  const { broadnID } = useParams()
  const res = usePreload(() => backend.adminGetBriefFull(broadnID))

  const { brief } = res?.data || {}
  const { subject, executionBrief, conversation } = brief || {}
  const { weburls = [] } = executionBrief || {}

  return (
    <Preload className="brief">
      <BriefHeader {...brief} />
      <div>
        <Tabs defaultValue="general" keepMounted={false}>
          <Tabs.List>
            <Tabs.Tab value="general">General</Tabs.Tab>
            <Tabs.Tab value="urls">URL's ({weburls?.length})</Tabs.Tab>
            <Tabs.Tab value="conversations">Conversations ({conversation?.length})</Tabs.Tab>
            <Tabs.Tab value="workflow">Workflow</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="general">
            <BriefGeneral {...brief} />
          </Tabs.Panel>

          <Tabs.Panel value="urls">
            <BriefUrls {...brief} />
          </Tabs.Panel>

          <Tabs.Panel value="conversations">
            <BriefConversations {...brief} />
          </Tabs.Panel>

          <Tabs.Panel value="workflow">
            <BriefWorkflow {...brief} />
          </Tabs.Panel>

          <Tabs.Panel value="settings">Settings tab content</Tabs.Panel>
        </Tabs>
      </div>
    </Preload>
  )
}

export default Full
