import './MainLogo.scss'
import { NavLink, Link } from 'react-router-dom'
import { Image } from '@mantine/core'

export function AgentHerbie(props) {
  return (
    <div className="MainLogo" >
      <NavLink to="/">
        <Image src="/AgentHerbieVStack.png" alt="Agent Herbie" fit="contain" className="MainLogo" {...props} />
      </NavLink>
    </div>
  )
}

export function AgentWanda(props) {
  return (
    <div className="MainLogo">
      <NavLink to="/">
        <Image src="/Wanda-nobgmax.png" alt="Agent Wanda" fit="contain" className="MainLogo" {...props} />
      </NavLink>
    </div>
  )
}

export function BroadnLogo(props) {
  return (
    <div className="MainLogo">
      <NavLink to="/">
        <img alt="broadn" src="/logo.avif" className="logoImg" {...props} />
      </NavLink>
    </div>
  )
}

export function AdClear(props) {
  return (
    <div className="MainLogo" >
      <NavLink to="/">
        <img alt="ADCLEAR AI" src="/ADCLEAR ORANGE.png" className="logoImg" {...props} />
      </NavLink>
    </div>
  )
}

export default function AgentOnCall(props) {
  if (window.APP_CONFIG && window.APP_CONFIG.environment && window.APP_CONFIG.environment.toString().includes('prod')) {
    return AgentHerbie(props)
  } else return AgentWanda(props)
}
