// import { Suspense, useState } from 'react'
// import { Await, useLoaderData } from 'react-router-dom'

// import PreLoad from 'components/PreLoad/PreLoad'

import backend from 'api/backend'

// const Users = () => {
//   const [query, setQuery] = useState()
//   const deferredQuery = useDeferredValue(query);
//   const isStale = query !== deferredQuery;

//   return (
//     <div>
//       <h1>Users</h1>

//       <label>
//         Search albums:
//         <input value={query} onChange={(e) => setQuery(e.target.value)} />
//         <button onClick={() => setQuery(backend.adminGetAllUsers())}>Update</button>
//       </label>

//       <PreLoad onUpdate={query} resolve={backend.getMyUserWithMembership()}>
//         {(data) => <pre>{JSON.stringify(data, 2, 2)}</pre>}
//       </PreLoad>
//     </div>
//   )
// }

import { Suspense, useState, useEffect, useMemo, useDeferredValue } from 'react'
import { Await } from 'react-router-dom'
import { use } from './SearchResults'

import SearchResults from './SearchResults.js'
import { fetchData } from './data.js'

import { Preload, usePreload } from 'components/PreLoad/PreLoad'

const getData = async (query) => {
  const fn = query ? 'getMyUserWithMembership' : 'adminGetAllUsers'
  const result = await backend[fn]()

  const time = new Date().toISOString()
  return { time, query, fn, result }
}

const SearchResults2 = ({ query }) => {
  const albums = usePreload(getData(query))
  const { onUpdate, data } = albums

  return (
    <div>
      <button
        onClick={() =>
          onUpdate(() => {
            return { test: 123, ...data, alio: 'walio' }
          })
        }
      >
        Update
      </button>

      <Preload {...albums}>
        <div style={{ border: 'solid 1px red', padding: '1em' }}>
          <h2>Hello: {query}</h2>
          <button onClick={() => onUpdate(getData(123))}>Update</button>
          <pre>{JSON.stringify(albums, 2, 2)}</pre>
        </div>
      </Preload>
    </div>
  )
}

export default function Users() {
  const [query, setQuery] = useState('')
  const deferredQuery = useDeferredValue(query)
  const isStale = query !== deferredQuery

  return (
    <>
      <label>
        Search albums: [{isStale + ''}]
        <input value={query} onChange={(e) => setQuery(e.target.value)} />
      </label>
      <SearchResults2 query={deferredQuery} />
    </>
  )
}

// export default Users
