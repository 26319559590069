import {
  useMantineTheme,
  Divider,
  Container,
  NavLink,
  AppShell,
  ScrollArea,
  Badge,
  Button,
  Avatar,
  Group,
  Skeleton,
  Space,
  Stack,
  Text,
  Tooltip,
  NativeScrollArea,
} from '@mantine/core'
import React, { useEffect, useContext, useState, useRef, useCallback, Fragment } from 'react'
import {
  IconChevronsLeft,
  IconWallet,
  IconChevronsRight,
  IconUsersGroup,
  IconSparkles,
  IconSettings2,
  IconHome2,
  IconChevronDown,
  IconCalendarStats,
  IconGauge,
  IconChevronRight,
  IconCircleOff,
} from '@tabler/icons-react'
import AuthContext from './components/Providers/AuthContext'
import MainLogo from './components/MainLogo/MainLogo'
import { useDisclosure } from '@mantine/hooks'
import { toJS } from 'mobx'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

const AppShellResponsive = observer((props) => {
  const auth = useContext(AuthContext)
  const [user, setUser] = useState(toJS(auth.user))
  const [opened, { toggle }] = useDisclosure()
  const navigate = useNavigate()

  useEffect(() => {
    let curUser = toJS(auth.user)
    if (!!curUser && curUser !== user) {
      if (curUser && !curUser.roles) curUser.roles = []
      setUser(curUser)
    }

    if (!curUser) return navigate('/login')
  }, [auth.user])

  const footer = (
    <div className="footer">
      <div className="footerRow">
        <div>Copyright © {new Date().getFullYear()} Agent Herbie Inc.</div>
        <div> All rights reserved.</div>
      </div>
    </div>
  )
  return (
    <AppShell
      header={{ height: { base: 0 } }}
      navbar={{
        breakpoint: 'sm',
        // collapsed: !opened,
        // transition: 'width 0.6s ease',
        padding: 'md',
        width: { base: opened ? 150 : 50 },
      }}
      disabled={user && user.email ? false : true}
    >
      <AppShell.Navbar p={0}>
        <div style={{ height: '10px' }}></div>
        <AppShell.Section w="100%">
          <Group>
            <MainLogo w={30} style={{ marginLeft: '10px' }} />
          </Group>
        </AppShell.Section>

        <Divider my="md" />
        <AppShell.Section grow padding="md" w="100%">
          <Stack align="stretch" justify="flex-start" gap={0}>
            <Tooltip label="Your Reports - coming soon ..." color="gray">
              <NavLink
                component={Link}
                to="/"
                leftSection={<IconGauge size={24} stroke={1} variant="subtle" />}
                label={opened ? 'Your reports' : null}
                color="violet"
                variant="subtle"
                h={42}
                active
              />
            </Tooltip>
          </Stack>
        </AppShell.Section>

        {user && user.roles && (user.roles.includes('broadn') || user.roles.includes('admin')) ? (
          <AppShell.Section p={0}>
            <Divider my="md" />
            <Tooltip label="All executed briefs" color="gray">
              <NavLink
                component={Link}
                to="/admin/allbriefs"
                leftSection={<IconSparkles size={24} w={24} stroke={1} />}
                label={opened ? 'All Briefs' : null}
                color="orange"
                variant="subtle"
                h={42}
                active
              />
            </Tooltip>

            <Tooltip label="All users" color="gray">
              <NavLink
                component={Link}
                to="/admin/allusers"
                label={opened ? 'All Users' : null}
                leftSection={<IconUsersGroup size={24} w={24} stroke={1} />}
                color="orange"
                variant="subtle"
                h={42}
                active
              />
            </Tooltip>

            {/* <Tooltip label="Users" color="gray">
              <NavLink
                component={Link}
                to="/admin/users"
                label={opened ? 'Users' : null}
                leftSection={<IconUsersGroup size={24} w={24} stroke={1} />}
                color="orange"
                variant="subtle"
                h={42}
                active
              />
            </Tooltip> */}

            <Tooltip label="Global config" color="gray">
              <NavLink
                component={Link}
                to="/admin/config"
                label={opened ? 'Global configs' : null}
                leftSection={<IconSettings2 size={24} w={24} stroke={1} />}
                color="gray.1"
                variant="subtle"
                h={42}
                active
              />
            </Tooltip>
          </AppShell.Section>
        ) : null}
        <AppShell.Section p={0}>
          <Divider my="md" />

          {/* <NavLink
            leftSection={<IconCalendarStats size={24} w={24} stroke={1} />}
            label={opened ? 'Subscription' : null}
            color="violet"
            variant="subtle"
            h={42}
            active
          /> */}

          <Tooltip label="Billing" color="gray">
            <NavLink
              leftSection={<IconWallet size={24} w={24} stroke={1} />}
              label={opened ? 'Billing' : null}
              color="violet"
              variant="subtle"
              to="/billing"
              component={Link}
              h={42}
              active
              // onClick={() => navigate('/billing')}
            />
          </Tooltip>

          <NavLink
            leftSection={
              user ? (
                <Avatar
                  src={user.profileImageUrl || '/user.jpg'}
                  alt={user.displayName}
                  radius="xl"
                  size={24}
                  w={24}
                  color="violet"
                  variant="subtle"
                />
              ) : null
            }
            label={opened && user ? <Text size="xs">{user.displayName}</Text> : null}
            color="violet"
            variant="subtle"
            h={42}
            active
          >
            {opened ? (
              <NavLink
                label=<Text size="xs">Logout</Text>
                onClick={() => {
                  auth.logout()
                  navigate('/')
                }}
                color="violet"
                variant="subtle"
                active
              />
            ) : null}
          </NavLink>
          <Button
            onClick={toggle}
            variant="transparent"
            size="xs"
            h={42}
            color="violet"
            leftSection={opened ? <IconChevronsLeft size={24} stroke={1} /> : <IconChevronsRight size={24} stroke={1} />}
          ></Button>
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
        <Space h="xl" />
        <AppShell.Footer>{footer}</AppShell.Footer>
      </AppShell.Main>
    </AppShell>
  )
})

export default AppShellResponsive
