import { Button } from '@mantine/core'
import { IconBriefcase, IconScale, IconTargetArrow, IconBallpen, IconWand } from '@tabler/icons-react'
import './Dashboard.scss'
import { useState } from 'react'

const categories = [
  {
    label: 'Market research',
    Icon: IconBriefcase,
    text: 'Hey Herbie,\n\nCan you prepare a market research report for me?\n\nThanks',
  },
  {
    label: 'Competitor analysis',
    Icon: IconScale,
    text: 'Hey Herbie,\n\nCan you write run a competitor analysis for my company?\n\nThanks',
  },
  {
    label: 'Company research',
    Icon: IconTargetArrow,
    text: 'Hey Herbie,\n\nCan you carry out an in-depth company research for me?\n\nThanks',
  },
  {
    label: 'Write a memo',
    Icon: IconBallpen,
    text: 'Hey Herbie,\n\nCan you help me draft an investment memo?\n\nThanks',
  },
  {
    label: 'Find investors',
    Icon: IconWand,
    text: 'Hey Herbie,\n\nCan you help me find relevant investors for my startup?\n\nThanks',
  },
]

const Dashboard = () => {
  const [active, setActive] = useState(categories[0])
  const [text, setText] = useState(active.text)

  return (
    <div className="container dashboard">
      <h1>Supercharge your productivity</h1>
      <p>Herbie is here to help across a wide set of use-cases. Do in an hour what used to take all week.</p>

      <div className="dashboard__form">
        <div className="dashboard__actions">
          <ul>
            {categories.map((item, key) => (
              <li key={key}>
                <Button
                  variant={item === active ? 'filled' : 'light'}
                  size="lg"
                  leftSection={<item.Icon />}
                  fullWidth
                  radius="md"
                  justify="left"
                  onClick={() => {
                    setActive(item)
                    setText(item.text)
                  }}
                >
                  {item.label}
                </Button>
              </li>
            ))}
          </ul>
        </div>
        <div className="dashboard__input">
          <textarea value={text} onChange={({ target }) => setText(target.value)} />
          <div className="dashboard__input__text">
            <Button variant="outline" size="lg" radius="md" justify="left" onClick={() => {}}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
