import './Admin.scss'
import ServiceContext from 'components/Providers/ServiceContext'
import AuthContext from 'components/Providers/AuthContext'
import Spinner from 'components/Spinner/Spinner'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toJS } from 'mobx'
import Table from 'rc-table'

const Admin = () => {
  const service = useContext(ServiceContext)
  const auth = useContext(AuthContext)
  const loggedUser = toJS(auth.user)
  const [allUsers, setAllUsers] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!loggedUser || !loggedUser.roles.includes('broadn')) return navigate('/')

    service.adminGetAllUsers().then((users) => {
      setAllUsers(
        users.map((u) => {
          u.createdStr = new Date(u.createdAt).toLocaleString()
          return u
        })
      )
    })
  }, [])

  if (!allUsers) return <Spinner />

  const columns = [
    {
      title: 'CreatedAt',
      dataIndex: 'createdStr',
      key: 'createdStr',
      align: 'right',
    },
    {
      title: 'FirstName',
      dataIndex: 'firstName',
      key: 'firstName',
      align: 'right',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'right',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      align: 'right',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      align: 'right',
    },
    {
      title: 'WaitingList',
      dataIndex: 'waitingList',
      key: 'waitingList',
      align: 'right',
      render: (value, row, index) => {
        return value ? 'true' : 'false'
      },
    },
    {
      title: 'Providers',
      dataIndex: 'providers',
      key: 'providers',
      align: 'right',
      render: (value) => value && Object.keys(value).join(', '),
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      align: 'right',
      render: (value) => (value || []).join(', '),
    },
  ]
  return (
    <div className="Admin">
      <Table columns={columns} data={allUsers} sticky title={(currentData) => <div>All users: {currentData.length}</div>} rowKey="_key" />
    </div>
  )
}

export default Admin
