import { Badge } from '@mantine/core'

const BriefGeneral = ({ broadnID, subject, from, createdAt, attachments, options, executionBrief, emailRefs = [] }) => {
  const { status, brief, weburls = [] } = executionBrief || {}
  const colors = { confirmed: 'lime', true: 'lime', false: 'pink' }

  return (
    <div>
      <h2>Email details</h2>
      <ul>
        <li>
          <b>From: </b>
          {from}
        </li>
        <li>
          <b>Subject: </b>
          {subject}
        </li>
        <li>
          <b>Created at: </b>
          <time>{new Date(createdAt).toString()}</time>
        </li>
        <li>
          <b>Attachments: </b>
          {attachments?.length}
        </li>
        <li>
          <b>Options: </b>
          <span>
            Create draft:{' '}
            <Badge color={colors[options?.createDraft]} variant="light">
              {options?.createDraft ? 'Yes' : 'No'}
            </Badge>
          </span>
          &nbsp;&nbsp;
          <span>
            Is triage request:{' '}
            <Badge color={colors[options?.isTriageRequest]} variant="light">
              {options?.isTriageRequest ? 'Yes' : 'No'}
            </Badge>
          </span>
        </li>
        <li>
          <b>Status: </b>
          <Badge color={colors[status]} variant="light">
            {status}
          </Badge>
        </li>
        <li>
          <b>Key: </b>
          <i className="desc">{broadnID}</i>
        </li>
      </ul>
      <div>
        <div>
          <b>Brief:</b>
        </div>
        <code>
          <pre>{brief}</pre>
        </code>
      </div>

      {/* <div>
        <div>
          <b>Web URL's ({weburls?.length})</b>
        </div>
        <ol className="brief__urls">
          {weburls.map((item, key) => (
            <li key={key}>
              <div className="desc">{item.description}</div>
              <a href="">{item.url}</a>
            </li>
          ))}
        </ol>
      </div> */}

      <div>
        <div>
          <b>Email refs:</b>
        </div>
        <ol>
          {emailRefs.map((item, key) => (
            <li key={key}>
              <ul>
                {Object.entries(item).map(([name, value]) => (
                  <li key={name}>
                    <b>{name}: </b>
                    <i className="desc">{value}</i>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

export default BriefGeneral
