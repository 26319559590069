import { useMemo } from 'react'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { MantineProvider, Button, Group, createTheme } from '@mantine/core'
import { observer } from 'mobx-react'

import buttonClasses from 'common/Buttons.css'
import groupsClasses from 'common/Groups.css'

import authService from 'api/auth'
import backendService from 'api/backend'
import AuthContext from 'components/Providers/AuthContext'
import ServiceContext from 'components/Providers/ServiceContext'

import LayoutHeader from './Layout.header'
import LayoutNav from './Layout.nav'

import './Layout.scss'

const Layout = () => {
  const auth = useMemo(() => authService, [])
  const backend = useMemo(() => backendService, [])

  return (
    <AuthContext.Provider value={auth}>
      <ServiceContext.Provider value={backend}>
        <MantineProvider theme={createTheme({ fontFamily: 'Inter, IBM Plex mono, sans-serif' })}>
          <div className="layout">
            <LayoutHeader />
            <LayoutNav />
            <div className="layout__content">
              <Outlet />
            </div>
            <footer className="layout__footer">
              <div>Copyright © {new Date().getFullYear()} Agent Herbie Inc.</div>
              <div> All rights reserved.</div>
            </footer>
          </div>
        </MantineProvider>
      </ServiceContext.Provider>
    </AuthContext.Provider>
  )
}

export default observer(Layout)
