import { Container, Card, Group, Text } from '@mantine/core'
import { memo, useState } from 'react'
import { Handle, useOnSelectionChange, Position } from 'reactflow'
import { BroadnError } from '../Types'
import { createErrorSection, createRefSection } from '../DisplayUtils'
import cn from 'classnames'
import './WFNodeType.css'

function WFNodeType({ id, data, isConnectable }) {
  const [isSelected, setSelected] = useState(false)

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      setSelected(!!nodes.find((n) => n.id === id))
    },
  })

  let taskSubLabel = ''
  switch (data.name) {
    case 'RunAIModel':
      taskSubLabel = data.taskDef.aiModel
      break

    case 'ProcessEmailAttachments':
      taskSubLabel = data.input._tag
      break
  }

  return (
    <Container fluid>
      <Handle id="a" type="source" position={Position.Top} isConnectable={isConnectable} />

      <Card className={cn({ selectedNode: isSelected, terminalNode: data.isTerminalNode })} withBorder shadow="md" radius="md" w={'100%'}>
        <Card.Section withBorder inheritPadding py="xs">
          <Card.Section withBorder inheritPadding py="xs">
            <Group justify="center">
              <Text fs={'md'} fw={300}>
                {data.output.outputType || 'OutputType'}
              </Text>
            </Group>
          </Card.Section>
          <Group justify="center">
            <Text fs="sm" fw={500}>
              {data.name || ''}
            </Text>
          </Group>
          {taskSubLabel ? (
            <Group justify="center">
              <Text fs="sm" fw={300}>
                {taskSubLabel}
              </Text>
            </Group>
          ) : null}
        </Card.Section>

        {createErrorSection(data.errorList as BroadnError[])}
        {createRefSection(data.ref)}
      </Card>
      <Handle type="target" position={Position.Bottom} isConnectable={isConnectable} />
    </Container>
  )
}

export default memo(WFNodeType)
