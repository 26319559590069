import { MarkerType, Position } from 'reactflow'
import { ExecutionRunResult, BriefWithExecutionResults, ExecutionResult } from '../../../common/BriefTypes'
import TaskDef from '../Types'

const defaultOpts = {
  size: { width: 500, height: 150 },
  gap: { wDelta: 20, hDelta: 40 },
  nodeCount: {},
}

const defNodeProps = {
  // className: 'cardNode',
  className: '',
  type: 'wfNode',
  sourcePosition: Position.Top,
  targetPosition: Position.Bottom,
}

const defEdgeProps = {
  // animated: true,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 15,
    height: 15,
    color: '#797979',
  },
  style: {
    strokeWidth: 1,
    stroke: '#797979',
  },
}

function createEdge(node: any, parent: any, defaultOpts: any) {
  const res = {
    ...defEdgeProps,
    source: node.id,
    target: parent.id,
    id: `${node.id}---${parent.id}`,
    label: undefined,
  }

  if (defaultOpts.nextEdgeLabel) {
    res.label = defaultOpts.nextEdgeLabel
    
    defaultOpts.nextEdgeLabel  = null
  }
  return res
}

function createNode(node: any, depth: number, opts: { [k: string]: any | { [k: string]: any } }) {
  let nodesOnDepth = opts.nodeCount[depth] || 0
  nodesOnDepth++
  opts.nodeCount[depth] = nodesOnDepth

  let cn = {
    id: `${depth}-${nodesOnDepth}`,
    ...defNodeProps,
    data: {
      errorList: node.errorList || [],
      label: `!${node._tag}....`,
      output: {
        text: node.text,
        outputType: `${node._tag}`,
        ref: node.ref,
        _tag: node._tag,
      },
      input: node.taskInput ? node.taskInput.main : {},
      task: {},
      ref: node.ref,
    },
    style: {
      width: opts.size.width,
    },
    nodeOrigin: [0, 0],
    position: { x: opts.size.width * (nodesOnDepth - 1), y: (opts.size.height + opts.gap.hDelta) * depth },
  }

  if (node.taskInput) {
    cn.data.task = {
      name: node.taskInput.taskName,
    }

    switch (node.taskInput.taskName) {
      case 'RunLLMPrompt':
        cn.data.task = {
          prompt: node.taskInput.prompt ? node.taskInput.prompt : undefined,
          // inputCombiner: node.taskInput.main.inputCombiner ? node.taskInput.main.inputCombiner : undefined,
          promptBuilderFunction: node.taskInput.main.promptBuilderFunction ? node.taskInput.main.promptBuilderFunction : undefined,
          // aiModel: node.taskInput.main.aiModel ? node.taskInput.main.aiModel : undefined,
          // params: node.taskInput.main.params ? node.taskInput.main.params : undefined,
          xmlSelectorTag: node.taskInput.main.xmlSelectorTag ? node.taskInput.main.xmlSelectorTag : undefined,
          ...node.taskInput.main,
          ...cn.data.task,
          text: undefined,
          broadnID: undefined,
          _tag: 'RunLLMPrompt',
        }
        break

      case 'RunLLMPrompt':
        cn.data.input = node.taskInput.actualInput
        break

      default:
        break
    }
  }

  if (node._tag === 'BroadnError') {
    cn.data.output = {
      outputType: node._tag,
      name: node.name,
      message: node.message,
      location: node.location,
      ref: node.ref,
    } as any

    cn.data.errorList.push({
      name: node.name,
      message: node.message,
      location: node.location,
      ref: node.ref,
    })

    cn.data.input = node.taskInput ? (node.taskInput?.main as any) : null
  }

  return cn
}

function traverseTree(currentNode: any, parent: any | undefined, nodes: any[], edges: any[], depth: number, opts: any) {
  if (currentNode._tag && currentNode._tag == 'Right') {
    return traverseTree(currentNode.right, parent, nodes, edges, depth, opts)
  }
  if (currentNode._tag && currentNode._tag == 'Left') {
    return traverseTree(currentNode.left, parent, nodes, edges, depth, opts)
  }

  let children = []
  if (currentNode.taskInput && currentNode.taskInput.children) {
    children = Object.values(currentNode.taskInput.children)
  }

  const newNode = createNode(currentNode, depth, { ...opts })
  nodes.unshift(newNode)
  if (parent) {
    const newEdge = createEdge(newNode, parent, defaultOpts)
    edges.unshift(newEdge)
  } else {
    // newNode.type = 'output'
    // newNode.sourcePosition = undefined
    newNode.className = 'terminalNode'
  }

  if (currentNode.taskInput && currentNode.taskInput.children) {
    children.forEach((ch) => traverseTree(ch, newNode, nodes, edges, depth + 1, opts))
  }
}

export function resultsToGraph(result: any, nodeSelector: any): { nodes: any[]; edges: any[] } {
  console.time('traverseTree')
  const nodes: any[] = []
  const edges: any[] = []
  defaultOpts.nodeCount = {}
  traverseTree(result, null, nodes, edges, 0, defaultOpts)
  console.timeEnd('traverseTree')
  return { nodes, edges }
}

function nodeFromTaskDef(current: TaskDef, depth: number, opts: { [k: string]: any | { [k: string]: any } }) {
  let nodesOnDepth = opts.nodeCount[depth] || 0
  nodesOnDepth++
  opts.nodeCount[depth] = nodesOnDepth

  let cn = {
    id: `${depth}-${nodesOnDepth}`,
    ...defNodeProps,
    data: {
      name: current.name,
      label: `!${current.name}....`,
      taskDef: {
        queueName: current.queueName,
        ...current.data,
      },
      input: {},
      output: {},
      opts: {},
      errorList: [],
    },
    style: {
      width: opts.size.width,
    },
    nodeOrigin: [0, 0],
    position: { x: opts.size.width * (nodesOnDepth - 1), y: (opts.size.height + opts.gap.hDelta) * depth },
  }

  if (current.name === 'ContextBasedDecisionMaking') {
    cn.data.taskDef['resultMapping'] = []
  }

  if (['RunFunctionTask', 'RunAIModel'].includes(current.name)) {
    cn.data.output = {
      outputType: current.data.promptBuilderFunction || current.data.function,
    }
  }

  return cn
}

function traverseTaskDefTree(current: TaskDef, parent: any | undefined, nodes: any[], edges: any[], depth: number, opts: any) {
  const newNode = nodeFromTaskDef(current, depth, { ...opts })
  nodes.unshift(newNode)

  if (parent) {
    const newEdge = createEdge(newNode, parent, {...defaultOpts, ...opts})
    edges.unshift(newEdge)
  } else {
    newNode.className = 'terminalNode'
  }
  if (current.children && current.children.length == 0) {
    newNode.className = 'terminalNode'
  }

  if (current.name === 'ContextBasedDecisionMaking') {
    Object.entries(current.data.resultMapping).forEach(([k, v]) => {
      traverseTaskDefTree(v as TaskDef, newNode, nodes, edges, depth + 1, {
        ...opts,
        nextEdgeLabel: `when '${current.data.decisionAttributeName}' == '${k}'`,
      })
    })
  }

  if (current.children && current.children.length > 0) {
    current.children.forEach((ch) => traverseTaskDefTree(ch, newNode, nodes, edges, depth + 1, opts))
  }
}

export function graphFromTaskDef(task: TaskDef) {
  console.time('traverseTaskDefTree')
  const nodes: any[] = []
  const edges: any[] = []
  defaultOpts.nodeCount = {}
  traverseTaskDefTree(task, null, nodes, edges, 0, defaultOpts)
  console.timeEnd('traverseTaskDefTree')
  return { nodes, edges }
}
