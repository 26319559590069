import get from 'lodash/get'
import cn from 'classnames'

import css from './Table.module.css'

//-----------------------------------------------------------------------------------------------
const Table = ({ data, columns, rowKey, sticky }) => {
  const rows = [].concat(data)

  return (
    <table className={css.table}>
      <thead className={cn(css.thead, sticky && css.sticky)}>
        <tr className={css.tr}>
          {columns.map((item, key) => (
            <th className={css.th} key={key}>
              {item.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={css.tbody}>
        {rows.map((row, index) => (
          <tr className={css.tr} key={row[rowKey] || index}>
            {columns.map((column, key) => (
              <TableCell key={key} row={row} column={column} />
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

//-----------------------------------------------------------------------------------------------
const TableCell = ({ row, column }) => {
  const { id, dataIndex, index, render, align } = column || {}

  const valueIndex = id || dataIndex || index
  const value = get(row, valueIndex)

  let displayValue = value

  if (render) {
    displayValue = render(value, row)
  }

  return (
    <td style={{ textAlign: align }} className={css.td}>
      {displayValue}
    </td>
  )
}

//-----------------------------------------------------------------------------------------------
export default Table
