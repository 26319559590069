import { fetchData } from './data.js'

// Note: this component is written using an experimental API
// that's not yet available in stable versions of React.

// For a realistic example you can follow today, try a framework
// that's integrated with Suspense, like Relay or Next.js.

export default function SearchResults({ query }) {
  if (query === '') {
    return null
  }
  const albums = use(fetchData(`/search?q=${query}`))
  if (albums.length === 0) {
    return (
      <p>
        No matches for <i>"{query}"</i>
      </p>
    )
  }
  return (
    <ul>
      {albums.map((album) => (
        <li key={album.id}>
          {album.title} ({album.year})
        </li>
      ))}
    </ul>
  )
}

// This is a workaround for a bug to get the demo running.
// TODO: replace with real implementation when the bug is fixed.
const cache = new Map()

window.cache = cache
export function use(promise) {
  // if (!promise) return new Promise(() => null)

  // if (!cache.has(promise)) {
  //   cache.set(promise, promise)
  // }
  // promise = cache.get(promise)

  console.log('USE')
  if (promise.status === 'fulfilled') {
    // cache.delete(promise)
    return promise.value
  } else if (promise.status === 'rejected') {
    throw promise.reason
  } else if (promise.status === 'pending') {
    throw promise
  } else {
    promise.status = 'pending'
    promise.then(
      (result) => {
        promise.status = 'fulfilled'
        promise.value = result
      },
      (reason) => {
        promise.status = 'rejected'
        promise.reason = reason
      }
    )
    throw promise
  }
}
