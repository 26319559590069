import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import cn from 'classnames'

import { IconChevronRight, IconLogin, IconLogout, IconUser } from '@tabler/icons-react'

import auth from 'api/auth'

import './UserCard.scss'

const UserCard = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)

  if (!auth.user)
    return (
      <div className="user-card login">
        <NavLink to="/login">
          <IconLogin /> Login
        </NavLink>
      </div>
    )

  const { displayName, email, profileImageUrl } = auth.user
  return (
    <div>
      <div className={cn('user-card', { show })} onClick={() => setShow(!show)}>
        <div>
          <img src={profileImageUrl} alt={displayName} />
        </div>
        <div>
          <div className="title">{displayName}</div>
          <div className="email">{email}</div>
        </div>
        <IconChevronRight />
      </div>

      {show && (
        <ul className="user-card__sub-menu">
          <li>
            <NavLink to="/user/profile">
              <IconUser /> Profile
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                auth.logout()
                navigate('/login')
              }}
            >
              <IconLogout /> Logout
            </NavLink>
          </li>
        </ul>
      )}
    </div>
  )
}

export default observer(UserCard)
