import ReactFlow, { ReactFlowProvider, MiniMap, Controls, Background, useNodesState, useEdgesState, useOnSelectionChange } from 'reactflow'

import { filterWorkflows } from './BriefWorkflow.utils'

import TaskDefNode from 'screens/Brief/Workflow/TaskDefNodeTypes'

const nodeTypes = {
  wfNode: TaskDefNode,
}

const BriefWorkflowGraph = ({ data, onChange = () => null }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])

  const { graph = {} } = data || {}

  useOnSelectionChange({ onChange })

  return (
    data && (
      <div className="graph">
        <ReactFlow
          nodes={graph.nodes}
          edges={graph.edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          snapGrid={[25, 25]}
          fitView
          minZoom={0.2}
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </div>
    )
  )
}

const FlowWithProvider = (props) => {
  return (
    <ReactFlowProvider>
      <BriefWorkflowGraph {...props} />
    </ReactFlowProvider>
  )
}

export default FlowWithProvider
