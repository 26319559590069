import MainLogo from 'components/MainLogo/MainLogo'

const LayoutHeader = () => {
  return (
    <header className="layout__header">
      <h1>
        <MainLogo w={30} /> Herbie
      </h1>
    </header>
  )
}

export default LayoutHeader
