import Conversations from 'components/Conversations/Conversations'

const BriefConversations = ({ conversation = [] }) => {
  return (
    <div>
      <h2>Conversations ({conversation?.length})</h2>

      <Conversations conversation={conversation} />
    </div>
  )
}

export default BriefConversations
