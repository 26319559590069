import { rem, Space, ThemeIcon, Image, Group, JsonInput, Button, Grid, Card, Stack, Title, Text, GridCol, List } from '@mantine/core'
import { IconStack, IconArrowRight, IconVariablePlus } from '@tabler/icons-react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import React from 'react'
import { interval } from 'date-fns'

const PlandDescriptionCard = observer((props) => {
  const styles = {
    container: {
      // display: 'flex',
      // flexDirection: 'column',
      // alignItems: 'center',
      // borderRadius: '8px',
      backgroundColor: 'white',
      padding: '20px',
      cursor: 'pointer',
      fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Ubuntu, sans-serif',
    },
    title: {
      fontSize: '28px',
      fontWeight: 'bold',
      margin: '0px',
      color: '#00bda3',
    },
    price: {
      fontSize: '16px',
      color: '#333',
    },
    button: {
      marginTop: '16px',
      height: '44px',
      width: '264px',
      backgroundColor: '#00bda3',
      fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Ubuntu, sans-serif',
      fontSize: '16px',
      fontWeight: 500,
      color: 'black',
      padding: '8px 16px',
      borderRadius: '6px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: '#1E8C49',
      },
    },
    defaultButton: {
      marginTop: '16px',
      height: '44px',
      width: '264px',
      backgroundColor: '#ffffff',
      fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Ubuntu, sans-serif',
      fontSize: '16px',
      fontWeight: 500,
      color: 'black',
      padding: '8px 16px',
      borderRadius: '6px',
      border: '1px solid #00bda3',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease-in-out',
      '&:hover': {
        backgroundColor: '#1E8C49',
      },
    },
  }

  if (!props.plan) return null
  let pricing = props.plan.price.toString() || ' '
  const isNumbericPrice = Number.isInteger(Number.parseInt(pricing[0]))
  pricing = isNumbericPrice ? `US$ ${pricing}` : pricing

  const handleClick = () => {
    if (!!props.plan.paymentLink) window.open(`${props.plan.paymentLink}?prefilled_email=${props.email}`, '_blank', 'noopener,noreferrer')
  }

  return (
    <div style={styles.container}>
      <Stack align="center" gap="xs">
        <Text size="md" fw={500} c="gray.7">
          {props.defaultPlan ? 'You are already on this plan' : <span>&nbsp;</span>}
        </Text>

        <Text size="24px" fw={600}>
          {/* Starter Plan */}
          {props.plan.name || ''}
        </Text>

        <Group>
          <Text size="24px">{pricing}</Text>
          {isNumbericPrice ? (
            <Text size="xs" c="gray.9" style={{ textWrap: 'wrap', lineHeight: 0.9, maxWidth: '30px' }}>
              per {props.plan.interval}
            </Text>
          ) : null}
        </Group>

        {props.defaultPlan ? (
          <>
            <Button style={styles.defaultButton} onClick={handleClick}>
              Already using this plan
            </Button>
            <Text size="12px" c="#1a1a1a80">
              You can always return to this plan
            </Text>
          </>
        ) : (
          <>
            <Button color="#00bda3" style={styles.button} onClick={handleClick}>
              Subscribe
            </Button>
            <Text size="12px" c="#1a1a1a80">
              <span>&nbsp;</span>
            </Text>
          </>
        )}

        <List
          spacing="xs"
          size="sm"
          center
          icon={
            <ThemeIcon variant="white" color="teal" size={24} radius="xl">
              <IconStack style={{ width: rem(16), height: rem(16) }} />
            </ThemeIcon>
          }
        >
          {(props.features.features || []).map((f, index) => (
            <List.Item key={index}>{f}</List.Item>
          ))}
        </List>

        <Text size="12px" c="#1a1a1a80">
          Supported payment methods:
        </Text>
        <Group>
          <Image
            role="presentation"
            src="https://js.stripe.com/v3/fingerprinted/img/amex-b933c9009eeaf8cfd07e789c549b8c57.svg"
            alt="amex"
            id="amex"
          />
          <Image
            role="presentation"
            src="https://js.stripe.com/v3/fingerprinted/img/mastercard-86e9a2b929496a34918767093c470935.svg"
            alt="mastercard"
            id="mastercard"
          />
          <Image
            role="presentation"
            src="https://js.stripe.com/v3/fingerprinted/img/visa-fb36094822f73d7bc581f6c0bad1c201.svg"
            alt="visa"
            id="visa"
          />
          <Image
            role="presentation"
            src="https://js.stripe.com/v3/fingerprinted/img/google_pay-ca6cc2f4ee364c7966f8fabf064849fe.svg"
            alt="GOOGLE_PAY"
            id="GOOGLE_PAY"
          />
          <Image
            role="presentation"
            src="https://js.stripe.com/v3/fingerprinted/img/link-cadf7f2d4be971d3a20d4a333d3bff98.svg"
            alt="link"
            id="link"
            width={24}
            height={16}
          />
        </Group>
      </Stack>
    </div>
  )
})

export default PlandDescriptionCard
