import get from 'lodash.get'

import DateIcon from '../Icons/DateIcon'
import LocationIcon from '../Icons/LocationIcon'
import PeopleIcon from '../Icons/PeopleIcon'

const CompanyInfo = ({ company }) => {
  if (!company) {
    return null
  }
  const { display_name, headline } = company

  const list = [
    { Icon: DateIcon, label: 'Founded', name: 'founded' },
    { Icon: LocationIcon, label: 'Headquarters', name: 'location.name' },
    { Icon: PeopleIcon, label: 'Employees', name: 'size' },
    { label: 'Revenue', name: 'revenue' },
    { label: 'Total Funding', name: 'total_revenue' },
    { label: 'Key Products', name: 'products' },
  ]
  return (
    display_name && (
      <div className="section__company">
        <h2>{display_name}</h2>
        <ul>
          <li>{headline}</li>
          {list.map(({ Icon, label, name }, key) => {
            const value = get(company, name)
            return value ? (
              <li key={key} className="list-item">
                {Icon && <Icon />} <b>{label}:</b> {value}
              </li>
            ) : null
          })}
        </ul>
      </div>
    )
  )
}

export default CompanyInfo
