import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import cn from 'classnames'
import { useParams, useNavigate, Link, useLocation, Navigate } from 'react-router-dom'
import {
  Group,
  Tabs,
  Code,
  Card,
  Text,
  Title,
  Grid,
  List,
  Anchor,
  Badge,
  Accordion,
  Timeline,
  Button,
  Divider,
  Avatar,
  Space,
  Center,
  Stack,
  Paper,
  Container,
  TextInput,
  keys,
  rem,
} from '@mantine/core'
import {
  IconDotsVertical,
  IconBrain,
  IconEyeCog,
  IconUser,
  IconUniverse,
  IconGitBranch,
  IconGitPullRequest,
  IconGitCommit,
  IconMessageDots,
} from '@tabler/icons-react'
import { formatRelative, formatDistance } from 'date-fns'

const GeneralBriefView = observer((props) => {
  const { brief, runs } = props

  const formatDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleDateString('en-US')
  }

  let weburlsAsItems = null
  if (brief && brief.executionBrief && brief.executionBrief.weburls) {
    weburlsAsItems = (brief.executionBrief.weburls || []).map((url, index) => (
      <List.Item key={index}>
        <Text size="xs" style={{ marginBottom: 4, marginRight: 8 }} span>
          {url.description}
        </Text>
        <Anchor size="xs" href={url.url} target="_blank" style={{ lineHeight: 'inherit' }}>
          {new URL(url.url).hostname}
        </Anchor>
      </List.Item>
    ))
  }

  

  return (
    <Container fluid>
      <Paper shadow="xs" p="xl">
        <Card shadow="sm" p="lg" radius="md" withBorder>
          <Title order={2}>Email Details</Title>
          <Text>
            <strong>From:</strong> {brief.from}
          </Text>
          <Text>
            <strong>Subject:</strong> {brief.subject}
          </Text>
          <Text>
            <strong>Created At:</strong> {formatDate(brief.createdAt)}
          </Text>
          <Text>
            <strong>Attachments:</strong> {brief.attachments.length}
          </Text>

          <Text fw={500}>Options</Text>
          <Grid gutter="xs" grow>
            <Grid.Col span={6} offset={1}>
              <Text>Create Draft: {brief.options.createDraft ? 'Yes' : 'No'}</Text>
            </Grid.Col>
            <Grid.Col span={6} offset={1}>
              <Text>Is Triage Request: {brief.options.isTriageRequest ? 'Yes' : 'No'}</Text>
            </Grid.Col>
          </Grid>
          <Space h="md"></Space>
          <Accordion multiple>
            {brief.executionBrief ? (
              <Accordion.Item value="Execution Brief">
                <Group>
                  <Text>Status:</Text>
                  <Badge color="pink" variant="light">
                    {brief.executionBrief.status}
                  </Badge>
                </Group>
                <Text>
                  <strong>Key:</strong> {brief._key}
                </Text>
                <Stack>
                  <Text fw={500}>Brief:</Text>
                  <Code block ta={'left'} fs="xs" className="codeBlock" c="gray.8">
                    {brief.executionBrief.brief}
                  </Code>
                </Stack>
                <Text fw={500}>Web URLs</Text>
                <List size="xs" withPadding type="ordered" spacing={4}>
                  {weburlsAsItems}
                </List>
                <Space h="md"></Space>
              </Accordion.Item>
            ) : (
              <Accordion.Item value="Execution Brief">
                <Text c="red.5">Execution brief missing</Text>
              </Accordion.Item>
            )}
            <Space h="md"></Space>

            <Accordion.Item value="Email References">
              <Space h="md"></Space>
              <List type="ordered" withPadding>
                {brief.emailRefs.map((ref, index) => (
                  <List.Item key={index}>
                    <Card shadow="xs" p="md" m="sm">
                      <Text>Gmail ID: {ref.gmailID}</Text>
                      <Text>Thread ID: {ref.threadID}</Text>
                      <Text>Message ID: {ref.messageID}</Text>
                      <Text>References: {ref.references}</Text>
                      <Text>Received At: {formatDate(ref.receivedAt)}</Text>
                    </Card>
                  </List.Item>
                ))}
              </List>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Paper>

      <Space h="md" />
      <Group align="center" justify="center">
        <Timeline color="cyan" active={brief.conversation.length} lineWidth={1} bulletSize={30} w={'80%'}></Timeline>
      </Group>
      <Space h="md" />
    </Container>
  )
})
export default GeneralBriefView
